import { Option } from '../components/FilterComboBox';
import { Article } from '../components/ReportsFilterComboBox';
import { parseQuery } from '../sessions';

interface CentreInfo {
  id: string;
  name: string;
}

const basicSort = (a: Option, b: Option) => a.label.localeCompare(b.label);

export const readCentreOptions = (centres: CentreInfo[]): Option[] => {
  type OMap = Record<string, Option>;

  const centreMap = centres.reduce<OMap>((acc, centre) => {
    const value = centre.id;
    const label = centre.id;
    acc[value] = acc[value] || { group: 'centre', value, label };
    return acc;
  }, {});

  const centre: Option[] = Object.values(centreMap).sort(basicSort);

  return [...centre];
};

export const buildArticleQuery = (params: Article): string => {
  if (!params.id && !params.name) {
    return '';
  }
  const splitted = params.name.split(' ');
  if (splitted.length > 1) {
    const joined = splitted.join('-');
    return `${encodeURIComponent(params.id)}*${encodeURIComponent(joined)}`;
  }

  return `${encodeURIComponent(params.id)}*${encodeURIComponent(params.name)}`;
};

export const buildCentreQuery = (selected: Option[]): string | null => {
  if (!selected.length) return null;

  const grouped = selected.reduce<Record<string, Option[]>>((acc, item) => {
    (acc[item.group] = acc[item.group] || []).push(item);
    return acc;
  }, {});

  const result = [];
  const keys = ['centre'];
  for (const key in grouped) {
    if (keys.indexOf(key) > -1) result.push([key, ...grouped[key].map((o) => o.value)]);
  }

  return result.map((e) => e.join('.')).join('*');
};

export const getSearch = (): any => {
  return window.location.search
    .substr(1)
    .split('&')
    .map((x) => x.split('='))
    .filter(([k, v]) => k && v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: decodeURIComponent(v) }), {});
};

export const parseArticleSearchQuery = (query: string | null, options: Article[]): Article => {
  const blankArticle = {} as Article;

  if (!query) return blankArticle;

  const splitted = query.split('*');

  if (splitted.length <= 1) return blankArticle;

  const selectedItem = {
    id: splitted[0],
    name: splitted[1].split('-').length > 1 ? splitted[1].split('-').join(' ') : splitted[1],
  };

  if (options.find((o) => o.id === selectedItem.id && o.name === selectedItem.name)) {
    return selectedItem;
  }

  return blankArticle;
};

export const parseCentresSearchQuery = (query: string | null, options: Option[], decodeURI?: boolean): Option[] => {
  if (!query) return [];

  return parseQuery(query)
    .map(([group, value]) => {
      return options.find((o) => o.group === group && o.value === (decodeURI ? decodeURIComponent(value) : value));
    })
    .filter<Option>((o): o is Option => !!o);
};
