import { useMemo, useRef } from 'react';
import { safeDate } from './date-util';
import { CalendarEventHandlerProps, CalendarCellListeners } from './types';

const dirMap = {
  ArrowUp: 'up' as const,
  ArrowRight: 'right' as const,
  ArrowDown: 'down' as const,
  ArrowLeft: 'left' as const,
};

export const useEventHandler = (props: CalendarEventHandlerProps): CalendarCellListeners => {
  const ref = useRef(props);
  ref.current = props;

  return useMemo((): CalendarCellListeners => {
    const intermediate = (date?: string) => {
      const { onIntermediateChange } = ref.current;
      if (onIntermediateChange) onIntermediateChange(date ? safeDate(date) : undefined);
    };

    const select = (date: string) => {
      const { selectValue, onSelectedChange, pastEndDate, futureEndDate } = ref.current;

      if (pastEndDate > date || date > futureEndDate) return;

      intermediate();
      const value = safeDate(date);
      if (!selectValue[0] || selectValue[1]) onSelectedChange([value, null]);
      else if (date < selectValue[0]) onSelectedChange([value, safeDate(selectValue[0])]);
      else onSelectedChange([safeDate(selectValue[0]), value]);
    };

    return {
      onClick: (event) => {
        const { date } = event.currentTarget.dataset;
        if (!date) return;
        const { onActiveChange } = ref.current;
        onActiveChange(safeDate(date));
        select(date);
      },
      onKeyDown: (event) => {
        const { key, currentTarget } = event;
        const { date } = currentTarget.dataset;
        if (!date) return;

        if (key === 'Enter' || key === ' ') {
          select(date);
          event.preventDefault();
        }

        if (key in dirMap) {
          ref.current.onActiveMove({
            date: safeDate(date),
            direction: dirMap[key as keyof typeof dirMap],
          });
          event.preventDefault();
        }
      },
      onMouseEnter: (event) => {
        const { date } = event.currentTarget.dataset;
        const { selectValue } = ref.current;
        if (date && selectValue[0] && !selectValue[1]) {
          intermediate(date);
        }
      },
      onMouseLeave: (event) => {
        const { date } = event.currentTarget.dataset;
        const { selectValue } = ref.current;
        if (date && selectValue[0] && !selectValue[1]) {
          intermediate();
        }
      },
    };
  }, []);
};
