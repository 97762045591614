import clsx from 'clsx';
import { FC } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import { DownloadReportButton, XLSImage, GraphImage } from './views';
import { safeDate, toDateString } from '../Calendar/date-util';
import {
  columnLeft,
  columnLeftWithTabs,
  columnRight,
  mainWrapper,
  downloadButtonContainer,
  graphImageContainer,
} from './ReportsPageLayout.module.scss';

import { ReportsArticleFilterContainer, TabsContainer } from '../../containers/ReportsPageLayout';

interface ReportsPageLayoutProps extends WithIProps<'div'> {
  buttonDisabled: boolean;
  isInternalCompliance: boolean;
  onReportDownloadClick: () => void;
}

export const ReportsPageLayout: FC<ReportsPageLayoutProps> = ({
  buttonDisabled,
  isInternalCompliance,
  onReportDownloadClick,
  testID,
  className,
  ...rest
}) => {
  const currentDateString = toDateString(new Date());
  const safeCurrentDate = safeDate(currentDateString);
  const safePastDate = safeDate('2020-01-01');

  const diff =
    safeCurrentDate.getFullYear() * 12 +
    safeCurrentDate.getMonth() -
    (safePastDate.getFullYear() * 12 + safePastDate.getMonth());

  return (
    <div className={clsx(className, mainWrapper)} {...dataTestID(testID)} {...rest}>
      <div className={clsx({ [columnLeft]: !isInternalCompliance, [columnLeftWithTabs]: isInternalCompliance })}>
        {isInternalCompliance && (
          <TabsContainer
            id="reports-tabs"
            tabs={[
              {
                id: 'ARTICLE',
                name: 'By article',
              },
              {
                id: 'CENTRE',
                name: 'By centre',
              },
            ]}
            testID="reports-tabs"
          />
        )}
        <ReportsArticleFilterContainer
          range={{ monthsBefore: diff, monthsAfter: 1 }}
          yearFrom={safePastDate}
          testID="reports-filter-container"
        />
        <div className={downloadButtonContainer}>
          <DownloadReportButton onClick={onReportDownloadClick} disabled={buttonDisabled} testID="reports-page" />
        </div>
        <div className={graphImageContainer}>
          <GraphImage testID="graph-image" />
        </div>
      </div>
      <div className={columnRight}>
        <XLSImage testID="xls-image" />
      </div>
    </div>
  );
};
