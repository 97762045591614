import { createContext, FC, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useBootState } from '../boot';
import { Tab, TabInformation } from '../components/DatePicker';
import { Article } from '../components/ReportsFilterComboBox';
import { Group, Option } from '../components/FilterComboBox';
import { useProvider } from '../util/useProvider';
import { NotificationReportGenerationFailedMessage } from '../notifications/views';
import { useSessions } from '../sessions';
import { parseDateQuery, buildDateQuery, format, serialize } from '../sessions/session-filter-util';
import { useNotification } from '../notifications';
import { downloadReport } from '../api';
import { useCentres } from '../centres';
import {
  readCentreOptions,
  buildArticleQuery,
  buildCentreQuery,
  parseArticleSearchQuery,
  parseCentresSearchQuery,
  getSearch,
} from './reports-util';

export type TabId = 'CENTRE' | 'ARTICLE';

interface ArticleComboBox {
  articleOptions: Article[];
  focused: boolean;
  setFocusedContainer: (value: boolean) => void;
  onSelectArticle: (id: string, name: string) => void;
  selectedArticle: Article;
}

interface CentresComboBox {
  centreOptions: Option[];
  selectedCentres: Option[];
  setSelectedCentre: (selected: Option[]) => void;
  groups: Group[];
}

interface ReportsContextProps {
  articleComboBox: ArticleComboBox;
  centresComboBox: CentresComboBox;
  datePicker: DatePicker;
  onReportDownloadClick: () => void;
  setTabIdValue: (id: TabId) => void;
  tabId: TabId;
}

interface DatePicker {
  tabs: TabInformation[];
  onRangeSelect: (selectedRange: Tab | null) => void;
  selectedRange: Tab | null;
}

const ReportsContext = createContext<ReportsContextProps>({
  articleComboBox: {
    selectedArticle: {} as Article,
    onSelectArticle: () => {},
    setFocusedContainer: () => {},
    focused: false,
    articleOptions: [],
  },
  centresComboBox: {
    selectedCentres: [],
    setSelectedCentre: () => {},
    groups: [],
    centreOptions: [],
  },
  datePicker: {
    onRangeSelect: () => {},
    selectedRange: null,
    tabs: [],
  },
  onReportDownloadClick: () => {},
  setTabIdValue: () => {},
  tabId: 'ARTICLE',
});

export const useReports = (): ReportsContextProps => useContext(ReportsContext);

const tabs: TabInformation[] = [{ id: 'date', name: 'Download date' }];
const groups: Group[] = [{ name: 'centre', label: 'Centre' }];

export const ReportsProvider: FC = ({ children }) => {
  const { q } = getSearch();
  const [articleOptions, setArticleOptions] = useState<Article[]>([]);
  const [focused, setFocused] = useState<boolean>(false);
  const [tabId, setTabId] = useState<TabId>(q && q.includes('centre') ? 'CENTRE' : 'ARTICLE');

  const boot = useBootState();
  const history = useHistory();
  const { listData } = useSessions();
  const { search } = useLocation();
  const { centresList } = useCentres();

  const { dispatch: dispatchNotification, messages, assignMessage } = useNotification();

  const token = !boot.loading && boot.token;
  const centreOptions = useMemo(
    () => (centresList && readCentreOptions(centresList.map((centre) => ({ id: centre.id, name: centre.name })))) || [],
    [centresList],
  );

  const params = useMemo(() => new URLSearchParams(search), [search]);
  const selectedRange = useMemo(() => parseDateQuery(params.get('d')), [params]);

  const selectedArticle = useMemo(() => parseArticleSearchQuery(params.get('q'), articleOptions) || ({} as Article), [
    params,
    articleOptions,
  ]);
  const selectedCentres = useMemo(() => parseCentresSearchQuery(params.get('q'), centreOptions) || ([] as Option[]), [
    params,
    centreOptions,
  ]);

  useEffect(() => {
    listData?.sorted?.default
      .map((session) => ({
        id: session?.article?.id,
        name: session?.article?.product,
      }))
      .filter((item) => {
        const index = articleOptions.findIndex((option) => option.id === item.id && option.name === item.name);
        if (index <= -1) {
          setArticleOptions([...articleOptions, item]);
        }
        return null;
      });
  }, [listData, articleOptions]);

  const setTabIdValue = useCallback(
    (id: TabId) => {
      const { pathname } = history.location;
      history.push(`${pathname}`);
      setTabId(id);
    },
    [history],
  );

  const setSelectedArticle = useCallback(
    (selected: Article) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);

      const query = buildArticleQuery(selected);

      if (!query) params.delete('q');
      else params.set('q', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const setSelectedCentre = useCallback(
    (selected: Option[]) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);
      params.delete('page');

      const query = buildCentreQuery(selected);

      if (!query) params.delete('q');
      else params.set('q', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const onRangeSelect = useCallback(
    (selectedRange: Tab | null) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);

      const query = selectedRange && buildDateQuery(selectedRange);

      if (!query) params.delete('d');
      else params.set('d', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const onSelectArticle = useCallback(
    (id: string, name: string) => {
      const DTO = id && name && { id: id, name: name };
      setSelectedArticle(DTO || ({} as Article));
    },
    [setSelectedArticle],
  );

  const onReportDownloadClick = useCallback(() => {
    if (!selectedRange || !token || !token.id_token) return;

    if (tabId === 'ARTICLE' && (!selectedArticle || !selectedArticle.id)) return;
    if (tabId === 'CENTRE' && !selectedCentres.length) return;

    const { from, to } = selectedRange;

    const dateFrom = from ? serialize(format(from)) : null;
    const dateTo = to ? serialize(format(to)) : null;

    if ((!dateFrom && !dateTo) || !dateFrom) return;

    const centreIds = selectedCentres.map((centre) => centre.value);
    const joinedCentreIds = centreIds.join('*');
    const selectedQueryItem = tabId === 'ARTICLE' ? selectedArticle.id : joinedCentreIds;

    const query = dateTo
      ? `?type=${tabId.toLowerCase()}&q=${selectedQueryItem}&d=from.${dateFrom}*to.${dateTo}`
      : `?type=${tabId.toLowerCase()}&q=${selectedQueryItem}&d=from.${dateFrom}*to.${dateFrom}`;

    downloadReport(token, query)
      .then((response) => {
        if (!response.data.size) {
          const dateFromForNotification = dayjs(dateFrom).format('DD MMM YYYY');
          const dateToForNotification = dateTo?.length ? dayjs(dateTo).format('DD MMM YYYY') : null;

          assignMessage(
            <NotificationReportGenerationFailedMessage
              articleNumber={selectedArticle.id}
              centreIds={centreIds}
              dateFrom={dateFromForNotification}
              dateTo={dateToForNotification === dateFromForNotification ? null : dateToForNotification}
            />,
            'NO_DOWNLOAD_ACTIVITY',
          );

          dispatchNotification([
            'ADD',
            { title: 'No download activity found', body: messages.NO_DOWNLOAD_ACTIVITY },
            'ERROR',
          ]);
          return;
        }

        const fileName = response.headers['content-disposition'].split('filename=')[1];
        const contentType = response.headers['content-type'];

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: contentType }), fileName);
          return;
        }

        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => console.error(err));
  }, [selectedArticle, selectedCentres, tabId, selectedRange, token, messages, dispatchNotification, assignMessage]);

  const setFocusedContainer = useCallback((value: boolean) => {
    setFocused(value);
  }, []);

  const value = useMemo<ReportsContextProps>(
    () => ({
      articleComboBox: {
        selectedArticle,
        onSelectArticle,
        setFocusedContainer,
        focused,
        articleOptions,
      },
      centresComboBox: {
        selectedCentres,
        setSelectedCentre,
        groups,
        centreOptions,
      },
      datePicker: { tabs, onRangeSelect, selectedRange },
      onReportDownloadClick,
      setTabIdValue,
      tabId,
    }),
    [
      onRangeSelect,
      onSelectArticle,
      setSelectedCentre,
      setFocusedContainer,
      onReportDownloadClick,
      setTabIdValue,
      articleOptions,
      selectedArticle,
      selectedRange,
      focused,
      tabId,
      centreOptions,
      selectedCentres,
    ],
  );

  return useProvider(ReportsContext, value, children);
};
