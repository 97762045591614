import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useProvider } from '../util/useProvider';
import { buildQuery } from './session-filter-util';
import { Order, searchSortValues } from './sessions-sort-util';

interface SortContextProps {
  sort: SortProps;
}

interface SortProps {
  onClickSort: (key: string) => void;
  updateOrder: (key: string, orderToUpdate: Order) => void;
  order: Order[];
}

export type OrderKeyType = 'default' | 'desc' | 'asc';

const SortContext = createContext<SortContextProps>({
  sort: {
    onClickSort: () => {
      throw new Error('Missing `SessionSortProvider`');
    },
    updateOrder: () => {
      throw new Error('Missing `SessionSortProvider`');
    },
    order: [],
  },
});

export const useSessionSort = (): SortContextProps => useContext(SortContext);

const possibleOrders: OrderKeyType[] = ['default', 'asc', 'desc'];
export const initialOrder: Order[] = [
  {
    key: 'product',
    order: 'default',
    orderNo: 0,
    index: 0,
  },
  {
    key: 'KAD',
    order: 'default',
    orderNo: 0,
    index: 0,
  },
  {
    key: 'sitting',
    order: 'default',
    orderNo: 0,
    index: 0,
  },
  {
    key: 'specialArrangement',
    order: 'default',
    orderNo: 0,
    index: 0,
  },
  {
    key: 'download',
    order: 'default',
    orderNo: 0,
    index: 0,
  },
  {
    key: 'supervisors',
    order: 'default',
    orderNo: 0,
    index: 0,
  },
];

export const SessionSortProvider: FC = ({ children }) => {
  const orders: OrderKeyType[] = useMemo(() => possibleOrders, []);
  const [order, setOrder] = useState(initialOrder);

  const history = useHistory();

  const setInitialSortOrder = useCallback(
    () => {
      const initialSortOrder = searchSortValues();

      if (initialSortOrder && initialSortOrder.length) {
        const orderToUpdate = order.map((o) => {
          const filteredSortOrderValue = initialSortOrder.find((ssv) => ssv.key === o.key);

          if (filteredSortOrderValue) {
            return {
              ...o,
              order: filteredSortOrderValue.order,
              orderNo: orders.findIndex((o) => o === filteredSortOrderValue.order),
              index: filteredSortOrderValue.index,
            };
          }

          return o;
        });
        setOrder(orderToUpdate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchSortValues],
  );

  useEffect(() => {
    setInitialSortOrder();
  }, [setInitialSortOrder]);

  const buildURL = useCallback(
    (orders: Order[]) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);

      orders.sort((a, b) => a.index - b.index);
      const options = orders.map((o) => ({ group: o.key, value: o.order, label: o.key }));
      const query = buildQuery(options);

      if (!query) params.delete('sort');
      else params.set('sort', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const updateOrder = useCallback(
    (key: string, orderToUpdate: Order) => {
      if (!order) {
        return;
      }

      orderToUpdate.order = orders[orderToUpdate.orderNo];

      if (setOrder && key) {
        const newOrder = order.map((o) => (o.key === key ? orderToUpdate : o));
        setOrder(newOrder);
        buildURL(newOrder.filter((o) => o.order !== 'default'));
      }
    },
    [buildURL, order, orders],
  );

  const onClickSort = useCallback(
    (key: string) => {
      if (!order) {
        return;
      }

      const currentOrder = order.find((o) => o.key === key);
      const orderToUpdate = currentOrder ? { ...currentOrder } : { key: key, order: 'default', orderNo: 0, index: 0 };

      if (order.some((o) => o.index > 0)) {
        orderToUpdate.index =
          orderToUpdate.orderNo === 0 ? Math.max(...order.map((_o) => _o.index)) + 1 : orderToUpdate.index;
      } else {
        orderToUpdate.index = 1;
      }

      if (orderToUpdate.orderNo >= orders.length - 1) {
        orderToUpdate.orderNo = 0;
      } else {
        orderToUpdate.orderNo = orderToUpdate.orderNo + 1;
      }

      if (setOrder && key) {
        setOrder(order.map((o) => (o.key === key ? orderToUpdate : o)));
        updateOrder(key, orderToUpdate);
      }
    },
    [order, orders.length, updateOrder],
  );

  const value = useMemo<SortContextProps>(
    () => ({
      sort: { onClickSort, updateOrder, order },
    }),
    [onClickSort, updateOrder, order],
  );

  return useProvider(SortContext, value, children);
};
