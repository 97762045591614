import clsx from 'clsx';
import { cloneElement, FC, ReactElement, useMemo } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import { hidden, menu } from './DropDown.module.scss';
import { usePopoverClassName } from './DropDownContainer';

export interface MenuListProps extends WithIProps<'ul'> {
  'aria-labelledby': string;
  items: ReactElement[];
  visible?: boolean;
}

export const DropdownMenuList: FC<MenuListProps> = ({ visible, items, testID, className, children, ...rest }) => {
  const popoverClassName = usePopoverClassName();

  const list = useMemo(
    () =>
      items.map((item) => {
        const addAttribute =
          item.props && !item.props.disabled && item.props.role !== 'separator' && item.props.role !== 'none';
        return (
          <li role="none" key={item.key || ''} {...dataTestID(testID, `item-${item.key}`)}>
            {cloneElement(item, {
              'data-item': addAttribute ? 'menuitem' : '',
              tabIndex: -1,
              role: (item.props && item.props.role) || 'menuitem',
            })}
          </li>
        );
      }),
    [items, testID],
  );

  return (
    <ul
      className={clsx(menu, popoverClassName, { [hidden]: !visible }, className)}
      role="menu"
      {...dataTestID(testID)}
      {...rest}
    >
      {list}
      {children}
    </ul>
  );
};
