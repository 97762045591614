import { AxiosRequestConfig } from 'axios';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Falsy } from 'react-hooks-async';
import { useCentres } from '../../centres';
import { Route, useRoute } from '../../Routes';
import { useProvider } from '../../util/useProvider';
import { Supervisor, useAuthRequest, Result, SupervisorsRequest } from '../../api';
import { useBootState } from '../../boot';
import { Option } from '../../components/FilterComboBox';

const useSupervisorsRequest = (id: string | Falsy): Result<Supervisor[]> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => id && { url: `/venue-users/${id}` }, [id]);
  const request = useAuthRequest<Supervisor[]>(params);
  return (params && request) || null;
};

export const useSupervisorsAssignRequest = (
  id: string | Falsy,
  data: SupervisorsRequest[],
): Result<Supervisor[]> | null => {
  const params = useMemo(
    (): AxiosRequestConfig | Falsy =>
      id && data.length && { url: `/venue-users/${id}/assign`, method: 'POST', data: JSON.stringify(data) },
    [data, id],
  );
  const request = useAuthRequest<Supervisor[]>(params);
  return (params && request) || null;
};

export const useSupervisorsRemoveRequest = (
  id: string | Falsy,
  data: SupervisorsRequest[],
): Result<Supervisor[]> | null => {
  const params = useMemo(
    (): AxiosRequestConfig | Falsy =>
      id && data.length && { url: `/venue-users/${id}/remove`, method: 'POST', data: JSON.stringify(data) },
    [data, id],
  );
  const request = useAuthRequest<Supervisor[]>(params);
  return (params && request) || null;
};

export const sortSupervisorsInCell = (selected: Option[], supervisors: Supervisor[]): Supervisor[] => {
  const selectedSupervisors = selected.map((item) => item.value);
  let supervisorsCopy = JSON.parse(JSON.stringify(supervisors)) as Supervisor[];

  supervisorsCopy = supervisorsCopy.sort((a, b) => a.fullName.localeCompare(b.fullName));

  let selectedSupervisorsObjs = [];
  for (const selectedSupervisor of selectedSupervisors) {
    const selected = supervisorsCopy.find((s) => selectedSupervisor === s.fullName);

    if (selected) {
      selectedSupervisorsObjs.push(selected);
      supervisorsCopy = supervisorsCopy.filter((s) => s.fullName !== selected.fullName);
    }
  }

  if (selectedSupervisorsObjs.length) {
    selectedSupervisorsObjs = selectedSupervisorsObjs.sort((a, b) => b.fullName.localeCompare(a.fullName));
    for (const selectedObj of selectedSupervisorsObjs) {
      supervisorsCopy.unshift(selectedObj);
    }
  }

  return supervisorsCopy;
};

export type SupervisorsError = '403' | '404' | '500' | 'unknown_error' | null;

interface SupervisorsContextProps {
  loading: boolean;
  error: SupervisorsError | null;
  data: Supervisor[] | null;
}

const SupervisorsContext = createContext<SupervisorsContextProps>({ loading: true, error: null, data: null });

export const useSupervisors = (): SupervisorsContextProps => useContext(SupervisorsContext);

export const SupervisorsProvider: FC = ({ children }) => {
  const route = useRoute();
  const { loading: centresLoading } = useCentres();
  const selected = route[0] === Route.SUPERVISORS && route[1].params.id;
  const supervisors = useSupervisorsRequest(!centresLoading && selected);

  const loading = !!supervisors && !(supervisors.error || supervisors.result);

  const cerror = supervisors && supervisors.error;
  const { dispatch } = useBootState();

  const [internalError, setInternalError] = useState<SupervisorsError>(null);

  useEffect(() => {
    if (cerror && 'isAxiosError' in cerror && cerror.response) {
      switch (cerror.response.status) {
        case 401:
          dispatch(['RESPONSE_401', cerror]);
          break;
        case 403:
          if (cerror.response.data.error === 'no_access_to_venue_users') {
            setInternalError('403');
          } else {
            dispatch(['RESPONSE_403', cerror]);
          }
          break;
        case 500:
          setInternalError('500');
          break;
        default:
          setInternalError('unknown_error');
          break;
      }
    } else if (cerror) {
      setInternalError('500');
    }
  }, [cerror, dispatch]);

  const data = useMemo(() => supervisors?.result || null, [supervisors]);

  const value = useMemo<SupervisorsContextProps>(() => ({ loading, error: internalError, data }), [
    data,
    internalError,
    loading,
  ]);
  return useProvider(SupervisorsContext, value, children);
};
