import { AxiosError, AxiosRequestConfig } from 'axios';
import { createContext, createElement, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Falsy } from 'react-hooks-async';
import { Centre, Message, Result, useAuthRequest } from '../api';
import { useCentres } from '../centres';
import { useNotification } from '../notifications';
import { useEventListener } from '../util/EventDispatcher';

const useMessagesRequest = (run: number | Falsy, centre: Centre | null): Result<Message[]> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => run && centre && { url: `/notifications/${centre.id}` }, [
    centre,
    run,
  ]);
  const request = useAuthRequest<Message[]>(params);
  return (params && request) || null;
};

export const useReadMessageRequest = (messageIds: string[] | Falsy): Result<string> | null => {
  const params = useMemo(
    (): AxiosRequestConfig | Falsy =>
      messageIds &&
      messageIds.length && { method: 'POST', url: '/notifications/read', data: JSON.stringify(messageIds) },
    [messageIds],
  );
  const request = useAuthRequest<string>(params);
  return (params && request) || null;
};

interface Context {
  messages: Message[] | null;
  loading: boolean;
  error: AxiosError | Error | null;
}

const Context = createContext<Context>({
  messages: null,
  loading: false,
  error: null,
});

export const useMessagesProvider = (): Context => useContext(Context);

const useRefteshToken = (): number => {
  const [token, setToken] = useState(1);
  useEventListener(
    'refreshMessages',
    useCallback(() => {
      setToken((token) => token + 1);
    }, [setToken]),
  );
  return token;
};

export const MessagesProvider: FC = ({ children }) => {
  const { dispatch: dispatchNotification, messages: notificationMessages } = useNotification();
  const { selectedCentre } = useCentres();
  const token = useRefteshToken();
  const ready = useMemo(() => selectedCentre && token, [selectedCentre, token]);

  const messages = useMessagesRequest(ready, selectedCentre);

  const loading = useMemo(() => !!messages && !(messages.error || messages.result), [messages]);
  const error = useMemo(() => messages && messages.error, [messages]);

  useEffect(() => {
    if (error) {
      dispatchNotification([
        'ADD',
        { title: 'Messages centre error', body: notificationMessages.FAILED_FETCH_MESSAGES },
        'ERROR',
      ]);
    }
  }, [dispatchNotification, error, notificationMessages]);

  const data = useMemo(() => messages?.result || null, [messages]);

  return createElement(Context.Provider, { value: { messages: data, loading, error } }, children);
};
