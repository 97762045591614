import { Change, OptionKey, ReducerState } from './types';
import { isSameOption, readSuggestions, sortOptions } from './util';

type H<P> = (state: ReducerState, payload: P) => ReducerState;
type HP = (state: ReducerState) => ReducerState;

const remove = <T>(list: T[], index: number) => list.slice(0, index).concat(list.slice(index + 1));

export const toggleSelected: H<OptionKey> = (state, target) => {
  const { selected, options } = state;
  const index = selected.findIndex((option) => isSameOption(option, target));
  const nselected = remove(selected, index);
  const nsuggested = readSuggestions(options, '', nselected);
  const lastChange: Change = { type: 'removed', option: selected[index] };
  return {
    ...state,
    selected: nselected,
    suggested: nsuggested,
    lastChange,
    input: '',
    activeSelect: -1,
    activeSuggest: -1,
  };
};

export const toggleSuggested: H<OptionKey> = (state, target) => {
  const { selected, suggested, options } = state;
  const index = suggested.findIndex((option) => isSameOption(option, target));
  const nselected = sortOptions([...selected, suggested[index]]);
  const nsuggested = readSuggestions(options, '', nselected);
  const lastChange: Change = { type: 'added', option: suggested[index] };
  return {
    ...state,
    selected: nselected,
    suggested: nsuggested,
    lastChange,
    input: '',
    activeSelect: -1,
    activeSuggest: -1,
  };
};

export const activeSelectChange: HP = (state) => {
  const { activeSuggest, activeSelect, selected, suggested, options } = state;

  if (activeSuggest >= 0) {
    const nselected = sortOptions([...selected, suggested[activeSuggest]]);
    const nsuggested = readSuggestions(options, '', nselected);
    const lastChange: Change = { type: 'added', option: suggested[activeSuggest] };
    return { ...state, input: '', activeSuggest: -1, lastChange, selected: nselected, suggested: nsuggested };
  }

  if (activeSelect >= 0) {
    const nselected = remove(selected, activeSelect);
    const nsuggested = readSuggestions(options, '', nselected);
    const lastChange: Change = { type: 'removed', option: selected[activeSelect] };
    return { ...state, input: '', activeSelect: -1, lastChange, selected: nselected, suggested: nsuggested };
  }

  return state;
};

export const unselectNext: HP = (state) => {
  const { activeSelect, selected, input, options } = state;
  const nselected = remove(selected, activeSelect + 1);
  const nsuggested = readSuggestions(options, input, nselected);
  const lastChange: Change = { type: 'removed', option: selected[activeSelect + 1] };
  return { ...state, selected: nselected, suggested: nsuggested, lastChange };
};

export const unselectPrev: HP = (state) => {
  const { input, activeSelect, selected, options } = state;

  if (activeSelect < 0) {
    const nselected = selected.slice(0, -1);
    const nsuggested = readSuggestions(options, input, nselected);
    const lastChange: Change = { type: 'removed', option: selected[selected.length - 1] };
    return { ...state, selected: nselected, suggested: nsuggested, lastChange };
  }

  const nselected = remove(selected, activeSelect - 1);
  const nsuggested = readSuggestions(options, input, nselected);
  const lastChange: Change = { type: 'removed', option: selected[activeSelect - 1] };
  return { ...state, activeSelect: activeSelect - 1, selected: nselected, suggested: nsuggested, lastChange };
};

export const clearSelected: HP = (state) => {
  const { input, activeSelect, options } = state;

  const nsuggested = readSuggestions(options, input, []);
  const lastChange: Change = { type: 'cleared' };
  return { ...state, activeSelect: activeSelect - 1, selected: [], suggested: nsuggested, lastChange };
};
