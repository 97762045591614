import { FC, ReactNode } from 'react';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import clsx from 'clsx';
import {
  messageStyle,
  messageIcon,
  messageTime,
  messageTitle,
  activeMessage,
  readMessage,
  activeContent,
  messageContent,
  messageLink,
} from './styles.module.scss';
import { TextSBold, TextSRegular, TextXSRegular } from '../../theme/typography.module.scss';
import { AnchorButton } from '../Button';

export interface MessageTitleProps extends WithIProps<'span'> {
  time: string;
}

export const MessageTitle: FC<MessageTitleProps> = ({ time, className, children, testID, ...rest }) => (
  <span className={clsx(messageTitle, TextSBold, className)} {...dataTestID(testID, 'title')} {...rest}>
    <MessageTime time={time} testID={testID} />
    {children}
  </span>
);

export interface MessageContentProps extends WithIProps<'span'> {
  active: boolean;
}

export const MessageContent: FC<MessageContentProps> = ({ active, className, children, testID, ...rest }) => (
  <span
    className={clsx(TextSRegular, messageContent, { [activeContent]: active }, className)}
    {...dataTestID(testID, 'content')}
    {...rest}
  >
    {children}
  </span>
);

export const MessageIcon: FC<WithIProps<'span'>> = ({ className, children, testID, ...rest }) => (
  <span className={clsx(messageIcon, className)} {...dataTestID(testID, 'icon')} {...rest}>
    {children}
  </span>
);

export interface MessageTimeProps extends WithIProps<'span'> {
  time: string;
}

export const MessageTime: FC<MessageTimeProps> = ({ time, className, children, testID, ...rest }) => (
  <span className={clsx(messageTime, TextXSRegular, className)} {...dataTestID(testID, 'time')} {...rest}>
    {time}
    {children}
  </span>
);

interface MessageLinkProps extends WithIProps<'div'> {
  link: string;
  product: string;
  specialArr: boolean;
  date: Date;
  sitting: 'AM' | 'PM' | 'EV';
}

export const MessageLink: FC<MessageLinkProps> = ({
  link,
  product,
  specialArr,
  date,
  sitting,
  className,
  children,
  testID,
  ...rest
}) => (
  <div className={clsx(messageLink, className)} {...dataTestID(testID, 'message-link')} {...rest}>
    <AnchorButton
      data-product={product}
      data-specialarr={specialArr}
      data-date={date}
      data-sitting={sitting}
      href={link}
      size="small"
      variant="outlined"
    >
      {children}
    </AnchorButton>
  </div>
);

export interface MessageProps extends WithIProps<'div'> {
  id: string;
  active: boolean;
  icon: ReactNode;
  read: boolean;
}

export const MessageContainer: FC<MessageProps> = ({
  icon,
  id,
  className,
  active,
  read,
  testID,
  children,
  ...rest
}) => (
  <div
    key={id}
    className={clsx(messageStyle, { [activeMessage]: active, [readMessage]: read }, className)}
    data-message={id}
    {...dataTestID(testID, `message-${id}`)}
    {...rest}
  >
    <MessageIcon testID={childTestID(testID, `message-${id}`)}>{icon}</MessageIcon>
    {children}
  </div>
);
