import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { SupervisorSession } from '../../api/models';
import { ConnectedPagination } from '../../components/Pagination';
import { WithIProps } from '../../util/test-id';
import { useSidebar } from './SidebarState';
import { useSupervisorsSessionList } from './SupervisorsSessionsListProvider';
import { useSupervisorsSessionItems } from './SupervisorsSessionsProvider';
import { SessionRowSupervisors, SupervisorsSessionsTableView, SupervisorsTableCheckbox } from './SupervisorsViews';

const SupervisorsSessionRow: FC<{ item: SupervisorSession }> = ({ item }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    dispatch,
    state: { selectedSessions },
  } = useSidebar();

  const selected = useMemo(() => selectedSessions.some((s) => s === item.id), [item, selectedSessions]);

  const onCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;

      if (isChecked) {
        dispatch(['SELECT_SESSIONS', [item.id]]);
      } else {
        dispatch(['UNSELECT_SESSIONS', [item.id]]);
      }
    },
    [dispatch, item],
  );

  useEffect(() => {
    if (inputRef.current) {
      if (selected) {
        inputRef.current.checked = true;
      } else {
        inputRef.current.checked = false;
      }
    }
  }, [selected]);

  return (
    <SessionRowSupervisors
      testID={`session-${item.id}`}
      product={item.product}
      date={item.date}
      sitting={item.sitting}
      supervisors={item.venueUsers}
      specialArrangements={item.specialArrangement}
      selected={selected}
      checkbox={
        <SupervisorsTableCheckbox
          onChange={onCheck}
          forwardRef={inputRef}
          inputId={`checkbox-${item.id}`}
          label="Session checkbox"
        />
      }
    />
  );
};

export const SupervisorsSessionsTable: FC = () => {
  const sessionList = useSupervisorsSessionList();
  const { totalPages, list } = sessionList;
  const {
    state: { visible },
  } = useSidebar();

  const itemArr = list && useSupervisorsSessionItems(list);

  const rows = (itemArr as SupervisorSession[])?.map(
    (s: SupervisorSession) => s && <SupervisorsSessionRow item={s} key={s.id} />,
  );

  return (
    <SupervisorsSessionsTableView
      rows={rows}
      testID="sessions-list"
      aria-describedby="upcoming-sessions-title"
      sidebarOpen={visible}
      pagination={totalPages > 1 && <ConnectedPagination aria-label="Session list navigation" total={totalPages} />}
    />
  );
};

export const SupervisorsMainCheckbox: FC<WithIProps<'input'>> = () => {
  const {
    dispatch,
    state: { selectedSessions },
  } = useSidebar();
  const inputRef = useRef<HTMLInputElement>(null);
  const { list } = useSupervisorsSessionList();
  const selectedAllList = useMemo(() => (list && list.every((s) => selectedSessions.includes(s))) || false, [
    list,
    selectedSessions,
  ]);

  useEffect(() => {
    if (inputRef.current && inputRef.current.checked && !selectedAllList) inputRef.current.checked = false;
  }, [selectedAllList]);

  const onCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;

      if (!list) return;

      if (isChecked) {
        dispatch(['SELECT_SESSIONS', [...list]]);
      } else {
        dispatch(['UNSELECT_SESSIONS', [...list]]);
      }
    },
    [dispatch, list],
  );

  if (!list) return null;

  return (
    <SupervisorsTableCheckbox
      forwardRef={inputRef}
      onChange={onCheck}
      inputId={`main-checkbox`}
      label="Main checkbox"
    />
  );
};
