import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { Sitting } from '../api';
import { DateFormat, DateTime } from '../components/DateTime';
import { SortIcon } from '../components/SortIcon';
import { Alert } from '../components/Icon';
import { useSessionSort, OrderKeyType } from '../sessions';
import { TextMBold, TextMRegular, TextSBold } from '../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../util/test-id';
import { ProgressBar } from '../notifications/views';
import { tableProgress } from '../notifications/views.module.scss';
import { SessionsFilterComboBox, SessionsDatePicker } from './index';
import {
  actionsArea,
  actionsTableCell,
  getHelp,
  pagination as paginationStyle,
  table,
  tableCell,
  tableHeaderCell,
  availabilityDate,
  articlesFilter,
  replacementFileWarning,
  newFileWarning,
} from './styles.module.scss';

export const GetHelpMessage: FC<WithIProps<'span'>> = ({ testID, children, className, ...rest }) => (
  <span className={clsx(getHelp, className)} {...dataTestID(testID, 'get-help-message')} {...rest}>
    <Alert /> File not available. Contact support for assistance.
    {children}
  </span>
);

export const ReplacementFileWarning: FC<WithIProps<'span'>> = ({ testID, className, ...rest }) => (
  <span
    className={clsx(replacementFileWarning, className)}
    {...dataTestID(testID, 'replacement-file-warning')}
    {...rest}
  >
    Replacement file
  </span>
);

export const NewFileWarning: FC<WithIProps<'span'>> = ({ testID, ...rest }) => (
  <span className={clsx(newFileWarning, TextMBold)} {...dataTestID(testID, 'new-file-warning')} {...rest}>
    (New)
  </span>
);

interface AvailableFromMessageProps extends WithIProps<'span'> {
  date: Date;
}

export const AvailableFromMessage: FC<AvailableFromMessageProps> = ({ date, testID, children, ...rest }) => (
  <span {...dataTestID(testID, 'get-help-message')} {...rest}>
    Available from <DateTime className={clsx(availabilityDate, TextMBold)} format={DateFormat.DATE} value={date} />
    {children}
  </span>
);

type ARIA_SORT = {
  asc: 'ascending';
  desc: 'descending';
  default: 'none';
};

const ARIA_SORT_VALUES: ARIA_SORT = { asc: 'ascending', desc: 'descending', default: 'none' };

export const SessionsTableHeader: FC<WithIProps<'thead'>> = ({ testID, ...rest }) => {
  const {
    sort: { onClickSort, order },
  } = useSessionSort();

  const product = order.find((o) => o.key === 'product');
  const KAD = order.find((o) => o.key === 'KAD');
  const sitting = order.find((o) => o.key === 'sitting');
  const download = order.find((o) => o.key === 'download');

  return (
    <thead {...dataTestID(testID)} {...rest}>
      <tr>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'product')}
          aria-sort={(ARIA_SORT_VALUES as any)[product?.order || 'default']}
        >
          <SortIcon
            order={(product?.order as OrderKeyType) || 'default'}
            isActive={product?.order !== 'default'}
            onClick={() => {
              onClickSort('product');
            }}
            columnLabel={'Product'}
            testID={childTestID(testID, 'product')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'key-assessment-date')}
          aria-sort={(ARIA_SORT_VALUES as any)[KAD?.order || 'default']}
        >
          <SortIcon
            order={(KAD?.order as OrderKeyType) || 'default'}
            isActive={KAD?.order !== 'default'}
            onClick={() => {
              onClickSort('KAD');
            }}
            columnLabel={'Exam date'}
            testID={childTestID(testID, 'key-assessment-date')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'sitting')}
          aria-sort={(ARIA_SORT_VALUES as any)[sitting?.order || 'default']}
        >
          <SortIcon
            order={(sitting?.order as OrderKeyType) || 'default'}
            isActive={sitting?.order !== 'default'}
            onClick={() => {
              onClickSort('sitting');
            }}
            columnLabel={'Sitting'}
            testID={childTestID(testID, 'sitting')}
          />
        </th>
        <th
          className={clsx(actionsArea, tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'audio-files')}
          aria-sort={(ARIA_SORT_VALUES as any)[download?.order || 'default']}
        >
          <SortIcon
            order={(download?.order as OrderKeyType) || 'default'}
            isActive={download?.order !== 'default'}
            onClick={() => {
              onClickSort('download');
            }}
            columnLabel={'Audio files'}
            testID={childTestID(testID, 'audio-files')}
          />
        </th>
      </tr>
    </thead>
  );
};

export interface SessionsTableProps extends WithIProps<'table'> {
  rows: ReactNode;
  pagination?: ReactNode;
}

export const SessionsTable: FC<SessionsTableProps> = ({ testID, rows, className, children, pagination, ...rest }) => (
  <table className={clsx(table, className)} {...dataTestID(testID)} {...rest}>
    <SessionsTableHeader testID={childTestID(testID, 'header')} />
    <tbody {...dataTestID(testID, 'body')}>{rows}</tbody>
    {pagination && (
      <tfoot>
        <tr>
          <td colSpan={7} className={paginationStyle}>
            {pagination}
          </td>
        </tr>
      </tfoot>
    )}
    {children}
  </table>
);

export interface SessionsRowProps extends WithIProps<'tr'> {
  product: string;
  date: Date;
  sitting: Sitting;
  actions: NonNullable<ReactNode>;
  specialArrangements: boolean;
}

const sittings = {
  AM: 'Morning',
  PM: 'Afternoon',
  EV: 'Evening',
};

export const SessionRow: FC<SessionsRowProps> = ({
  testID,
  product,
  date,
  actions,
  sitting,
  specialArrangements,
  ...rest
}) => (
  <tr {...dataTestID(testID)} {...rest}>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'product')}>
      {product} {specialArrangements && <span className={TextMBold}>Special Arr.</span>}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'key-assessment-date')}>
      <DateTime format={DateFormat.DATE} value={date} />
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'sitting')}>
      {sittings[sitting]}
    </td>
    <td className={clsx(tableCell, actionsTableCell, TextMRegular)} {...dataTestID(testID, 'audio-files')}>
      {actions}
    </td>
  </tr>
);

export interface SessionsTitleProps extends Omit<WithIProps<'h2'>, 'title'> {
  title: ReactNode;
}

export const SessionsFilter: FC<WithIProps<'div'>> = ({ className, ...rest }) => (
  <div className={clsx(articlesFilter, className)} {...rest}>
    <SessionsFilterComboBox />
    <SessionsDatePicker />
  </div>
);

export const PreparingFileMessage: FC<WithIProps<'div'>> = ({ testID }) => (
  <div {...dataTestID(testID, 'message')}>
    <span>Preparing file</span>
    <ProgressBar className={tableProgress} testID="sessions-table" />
  </div>
);
