import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import { ComponentType, FC, Fragment, ReactElement, ReactNode } from 'react';
import 'react-app-polyfill/ie11';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '../src/index.scss';
import { App } from './App';
import { BootProvider } from './boot';
import { CentresProvider } from './centres';
import { Routes } from './Routes';
import { SessionFilterProvider, SessionListProvider, SessionsProvider, SessionSortProvider } from './sessions';
import { SignatureProvider } from './singnature';
import { UserSessionExtensionProvider, UserSessionTimerProvider, UserInfoProvider, EulaProvider } from './userSession';
import { DialogState } from './pages/DialogState';
import {
  SidebarState,
  SupervisorsProvider,
  SupervisorsSessionsProvider,
  SupervisorsSessionsListProvider,
  SupervisorsSessionsFilterProvider,
} from './pages/supervisors';
import { NotificationProvider } from './notifications';
import { ReportsProvider } from './reports';
import { EventDispatcher } from './util/EventDispatcher';
import { MessagesProvider, MessagesState } from './messageCentre';
import { CookiesConsentState } from './cookies';
import { GAProvider } from './googleAnalytics';

const providers: ComponentType[] = [
  EulaProvider,
  NotificationProvider,
  BrowserRouter,
  Routes,
  EventDispatcher,
  BootProvider,
  CookiesConsentState,
  UserInfoProvider,
  CentresProvider,
  GAProvider,
  MessagesProvider,
  MessagesState,
  SessionsProvider,
  SessionFilterProvider,
  SessionSortProvider,
  SessionListProvider,
  SignatureProvider,
  SupervisorsProvider,
  SupervisorsSessionsProvider,
  SupervisorsSessionsFilterProvider,
  SupervisorsSessionsListProvider,
  SidebarState,
  DialogState,
  UserSessionExtensionProvider,
  UserSessionTimerProvider,
  ReportsProvider,
].reverse();

const reduceProvider = (children: ReactNode, Provider: ComponentType): ReactElement => <Provider>{children}</Provider>;

const Providers: FC = ({ children }) => providers.reduce(reduceProvider, <Fragment>{children}</Fragment>);

render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root'),
);
