import clsx from 'clsx';
import { FC, useEffect, useRef } from 'react';
import { TextSBold } from '../../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { AnchorButton, AnchorButtonProps, Button, ButtonProps } from '../Button';
import styles from './Tabs.module.scss';

export type TabListProps = WithIProps<'div'>;

export const TabList: FC<TabListProps> = ({ className, testID, ...rest }) => {
  return <div role="tablist" className={clsx(styles.tabList, className)} {...dataTestID(testID)} {...rest} />;
};

export interface TabAnchorButtonProps extends AnchorButtonProps {
  group: string;
  tabId: string;
  active?: boolean;
  href?: string;
}

export const TabAnchorButton: FC<TabAnchorButtonProps> = ({ active, group, tabId, href, className, ...rest }) => {
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (active && ref.current) ref.current.focus();
  }, [active]);

  return (
    <AnchorButton
      forwardRef={ref}
      variant="text"
      size="small"
      className={clsx(styles.tabButton, { [styles.inactiveTab]: !active }, TextSBold, className)}
      role="tab"
      aria-selected={active}
      data-tabid={tabId}
      id={childTestID(group, `tab-${tabId}`)}
      aria-controls={childTestID(group, `tabpanel-${tabId}`)}
      href={href}
      {...rest}
    />
  );
};

export interface TabButtonProps extends ButtonProps {
  group: string;
  tabId: string;
  active?: boolean;
}

export const AppBarTabButton: FC<TabButtonProps> = ({ active, group, tabId, className, ...rest }) => (
  <Button
    variant="text"
    size="small"
    className={clsx(styles.tabButton, { [styles.inactiveTab]: !active }, TextSBold, className)}
    role="menuitem"
    data-tabid={tabId}
    id={childTestID(group, `menuitem-${tabId}`)}
    {...rest}
  />
);

export const ReportsTabButton: FC<TabButtonProps> = ({ active, group, tabId, className, ...rest }) => (
  <Button
    variant="text"
    size="small"
    className={clsx(styles.tabButton, { [styles.inactiveTab]: !active }, TextSBold, className)}
    role="menuitem"
    data-tabid={tabId}
    id={childTestID(group, `reports-tab-${tabId}`)}
    {...rest}
  />
);

export const TabButton: FC<TabButtonProps> = ({ id, active, group, tabId, className, ...rest }) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const tabGroup = document.activeElement && document.activeElement.getAttribute('data-tab-group');
    if (active && tabGroup === group && ref.current) ref.current.focus();
  }, [active, group]);

  return (
    <Button
      data-tab-group={group}
      forwardRef={ref}
      variant="text"
      size="small"
      className={clsx(styles.tabButton, { [styles.inactiveTab]: !active }, TextSBold, className)}
      role="tab"
      tabIndex={active ? 0 : -1}
      aria-selected={active}
      data-tabid={tabId}
      id={childTestID(group, `tab-${id}`)}
      aria-controls={childTestID(group, `tabpanel-${id}`)}
      {...rest}
    />
  );
};

export interface TabPanelProps extends WithIProps<'div'> {
  group: string;
  tabId: string;
  active?: boolean;
}

export const TabPanel: FC<TabPanelProps> = ({ active, group, tabId, testID, ...rest }) => {
  return (
    <div
      role="tabpanel"
      id={childTestID(group, `tabpanel-${tabId}`)}
      aria-labelledby={childTestID(group, `tab-${tabId}`)}
      hidden={!active}
      {...dataTestID(testID)}
      {...rest}
    />
  );
};
