import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { AsyncTask, Falsy, useAsyncRun, useAsyncTask } from 'react-hooks-async';
import { useBootState } from '../boot';
import { sleep } from '../util/helpers';
import { SignatureResponse, TokenResponse } from './models';
import { Result, useRequest } from './use-request';

const API_DOMAIN = (process.env.REACT_APP_HOSTNAME || location.hostname).replace(/www\./, '').replace(/^/, 'api.');
const API_ENDPOINT = `https://${API_DOMAIN}`;

const baseURL = `${API_ENDPOINT}/api/v1`;

interface Params {
  token: TokenResponse;
  filename: string;
}

const signDownload = async (
  abortController: AbortController,
  { filename, token }: Params,
): Promise<SignatureResponse> => {
  const source = Axios.CancelToken.source();
  abortController.signal.addEventListener('abort', () => {
    source.cancel('canceled');
  });

  try {
    const end = Date.now() + 60000 * 5;
    while (end > Date.now()) {
      const response: AxiosResponse<SignatureResponse> = await Axios({
        baseURL,
        url: `/sign/${filename}`,
        headers: { Authorization: `Bearer ${token.id_token}` },
        cancelToken: source.token,
      });

      const { data } = response;
      if (data.retry_in) await sleep(data.retry_in * 1000);
      else return data;
    }

    throw new Error(`${filename} file signing timeout`);
  } catch (e) {
    throw Axios.isCancel(e) ? Object.assign(new Error(e.message), { name: 'AbortError' }) : e;
  }
};

export const downloadReport = async (token: TokenResponse, query: string): Promise<any> => {
  return await Axios({
    baseURL,
    url: `/reports${query}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token.id_token}` },
    responseType: 'blob',
  });
};

export interface SignedDownloadParams {
  filename: string;
}

const defaultParams: Params = {
  filename: '',
  token: { access_token: '', expires_in: 0, id_token: '', refresh_token: '', token_type: '' },
};

export const useSignedDownload = (params: SignedDownloadParams | Falsy): AsyncTask<SignatureResponse, [Params]> => {
  const boot = useBootState();
  const token = !boot.loading && boot.token;
  const task = useAsyncTask(signDownload);
  const rparams = useMemo(() => params && token && { filename: params.filename, token }, [params, token]);
  useAsyncRun(rparams && task, rparams || defaultParams);
  return task;
};

export const useAPIRequest = <T>(input: AxiosRequestConfig | Falsy): Result<T> => {
  return useRequest(useMemo(() => input && { ...input, baseURL }, [input]));
};

export const useAuthRequest = <T>(input: AxiosRequestConfig | Falsy): Result<T> => {
  const boot = useBootState();
  const token = !boot.loading && boot.token;

  return useAPIRequest(
    useMemo(
      () => input && token && { ...input, headers: { ...input.headers, Authorization: `Bearer ${token.id_token}` } },
      [input, token],
    ),
  );
};
