import { AxiosRequestConfig } from 'axios';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Falsy } from 'react-hooks-async';
import { Result, Session, SessionsResponse, useAuthRequest } from '../api';
import { useBootState, useIsHelpdesk, useIsInternalCompliance } from '../boot';
import { useCentres } from '../centres';
import { Centre } from '../api/models';
import { Route, useRoute, RouteResult } from '../Routes';
import { useProvider } from '../util/useProvider';
import { readSessions, SessionsData } from './readSessions';

const useSessionsItemRequest = (id: string | Falsy): Result<SessionsResponse> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => id && { url: `/sessions/${id}` }, [id]);
  const request = useAuthRequest<SessionsResponse>(params);
  return (params && request) || null;
};

const useSessionsListRequest = (run: boolean): Result<SessionsResponse> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => run && { url: `/sessions` }, [run]);
  const request = useAuthRequest<SessionsResponse>(params);
  return (params && request) || null;
};

const getSelected = (route: RouteResult, selectedCentre: Centre | null) => {
  if (route[0] === Route.CENTRE && route[1].params.id) {
    return route[1].params.id;
  }

  if (route[0] === Route.REPORTS && selectedCentre) {
    return selectedCentre.id;
  }

  return null;
};

type SessionsError =
  | 'failed_to_load_sessions'
  | 'no_access_to_sessions'
  | 'customer_not_found'
  | 'unknown_error'
  | null;

interface SessionsContextProps {
  loading: boolean;
  error: SessionsError | null;
  data: SessionsData | null;
  listData: SessionsData | null;
}

const SessionsContext = createContext<SessionsContextProps>({ loading: true, error: null, data: null, listData: null });

export const useSessions = (): SessionsContextProps => useContext(SessionsContext);
export const useSessionItem = (id: string): Session | null => {
  const { data } = useSessions();
  return data && data.items[id];
};

export const SessionsProvider: FC = ({ children }) => {
  const route = useRoute();
  const { loading: centresLoading, selectedCentre } = useCentres();
  const boot = useBootState();
  const isHelpdesk = useIsHelpdesk();
  const isInternalCompliance = useIsInternalCompliance();
  const { dispatch } = boot;

  const selected = getSelected(route, selectedCentre);
  const sessions = useSessionsItemRequest(!centresLoading && selected);

  const user = !boot.loading && boot.user;

  const hasAccessToSessionsList = useMemo(() => isInternalCompliance || isHelpdesk, [isInternalCompliance, isHelpdesk]);
  const sessionsList = useSessionsListRequest(hasAccessToSessionsList && route[0] === Route.REPORTS && !!user);

  const loading =
    route[0] !== Route.REPORTS
      ? !!sessions && !(sessions.error || sessions.result)
      : !!sessionsList && !(sessionsList.error || sessionsList.result);

  const cerror = sessions && sessions.error;
  const listError = sessionsList && sessionsList.error;

  const [internalError, setInternalError] = useState<SessionsError>(null);

  useEffect(() => {
    let err = null;

    if (cerror && 'isAxiosError' in cerror && cerror.response) err = cerror;
    if (listError && 'isAxiosError' in listError && listError.response) err = listError;

    if (!err || !err.response) return;

    switch (err.response.status) {
      case 401:
        dispatch(['RESPONSE_401', err]);
        break;
      case 403:
        if (err.response.data.error === 'no_access_to_sessions') {
          setInternalError('no_access_to_sessions');
        } else {
          dispatch(['RESPONSE_403', err]);
        }
        break;
      case 404:
        setInternalError('customer_not_found');
        break;

      case 500:
        setInternalError('failed_to_load_sessions');
        break;
      default:
        setInternalError('unknown_error');
        break;
    }
  }, [cerror, listError, dispatch]);

  const sessionsResult = sessions?.result || null;
  const sessionsListResult = sessionsList?.result || null;

  const sessionsData = useMemo(() => sessionsResult && readSessions(sessionsResult), [sessionsResult]);
  const sessionsListData = useMemo(() => sessionsListResult && readSessions(sessionsListResult), [sessionsListResult]);

  const value = useMemo<SessionsContextProps>(
    () => ({ loading, error: internalError, data: sessionsData, listData: sessionsListData }),
    [sessionsData, sessionsListData, loading, internalError],
  );
  return useProvider(SessionsContext, value, children);
};
