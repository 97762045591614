import { Session, Sitting, SupervisorSession } from '../api';
import { Option } from '../components/FilterComboBox';
import { Tab } from '../components/DatePicker';
import { hash } from './readSessions';

interface IsNewMap {
  [key: string]: string;
}

const sittingValues = { AM: 0, PM: 1, EV: 2 };
const sittingLabels = { AM: 'AM', PM: 'PM', EV: 'EVE' };

const basicSort = (a: Option, b: Option) => a.label.localeCompare(b.label);
const sittingSort = (a: Option, b: Option) => sittingValues[a.value as Sitting] - sittingValues[b.value as Sitting];

export const format = (date: Date): string =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];

export const serialize = (value: string): string => (/[.*]/.exec(value) ? JSON.stringify(value) : value);

const deserialize = (value: string): string | null => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

export const readSupervisorOptions = (supervisorSessions: SupervisorSession[]): Option[] => {
  type OMap = Record<string, Option>;

  const productMap = supervisorSessions.reduce<OMap>((acc, session) => {
    const value = hash(session.product);
    const label = session.product;
    acc[value] = acc[value] || { group: 'product', value, label };
    return acc;
  }, {});

  const sittingMap = supervisorSessions.reduce<OMap>((acc, { sitting }) => {
    const value = sitting;
    const label = sittingLabels[sitting];
    acc[value] = acc[value] || { group: 'sitting', value, label };
    return acc;
  }, {});

  const specialArrangementMap = supervisorSessions.reduce<OMap>((acc, session) => {
    const value = session.specialArrangement ? 'special_arrangement' : 'not_special_arrangement';
    const label = session.specialArrangement ? 'Yes' : 'No';
    acc[value] = acc[value] || { group: 'specialArrangement', value, label };
    return acc;
  }, {});

  const supervisorsMap = supervisorSessions.reduce<OMap>((acc, { venueUsers }) => {
    for (const venueUser of venueUsers) {
      const value = venueUser.fullName;
      const label = venueUser.fullName;

      acc[value] = acc[value] || { group: 'supervisors', value, label };
    }
    return acc;
  }, {});

  const product: Option[] = Object.values(productMap).sort(basicSort);
  const sitting: Option[] = Object.values(sittingMap).sort(sittingSort);
  const specialArrangement: Option[] = Object.values(specialArrangementMap).sort(basicSort);
  const supervisors: Option[] = Object.values(supervisorsMap).sort(basicSort);

  return [...product, ...sitting, ...specialArrangement, ...supervisors];
};

export const readOptions = (sessions: Session[]): Option[] => {
  type OMap = Record<string, Option>;

  const productMap = sessions.reduce<OMap>((acc, { article }) => {
    const value = article.pid;
    const label = article.product;
    const acronym = article.acronym;
    acc[value] = acc[value] || { group: 'product', value, label, acronym };
    return acc;
  }, {});

  const sittingMap = sessions.reduce<OMap>((acc, { sitting }) => {
    const value = sitting;
    const label = sittingLabels[sitting];
    acc[value] = acc[value] || { group: 'sitting', value, label };
    return acc;
  }, {});

  const specialArrangementMap = sessions.reduce<OMap>((acc, { article }) => {
    const value = article.specialArrangement ? 'special_arrangement' : 'not_special_arrangement';
    const label = article.specialArrangement ? 'Yes' : 'No';
    acc[value] = acc[value] || { group: 'specialArrangement', value, label };
    return acc;
  }, {});

  const newMarkerMap = sessions.reduce<OMap>((acc, { isNew }) => {
    const value = isNew ? 'new' : 'old';
    const label = isNew ? 'Yes' : 'No';
    acc[value] = acc[value] || { group: 'isNew', value, label };
    return acc;
  }, {});

  const product: Option[] = Object.values(productMap).sort(basicSort);
  const sitting: Option[] = Object.values(sittingMap).sort(sittingSort);
  const specialArrangement: Option[] = Object.values(specialArrangementMap).sort(basicSort);
  const newMarker: Option[] = Object.values(newMarkerMap).sort(basicSort);

  return [...product, ...sitting, ...specialArrangement, ...newMarker];
};

const defaultFilter = (sessions: Session[]) => sessions;
const defaultSupervisorFilter = (sessions: SupervisorSession[]) => sessions;

export const parseQuery = (query: string): [string, string][] => {
  const rex = /(?:^|\*)([^.]+)((?:\.(?:(?:"(?:\\"|[^"])*")|[^.*]*))*)/g;
  const rexval = /\.(?:("(?:\\"|[^"])*")|([^.]*))/g;

  const parsedQuery: [string, string][] = [];
  query.replace(rex, (_, group: string, values: string) => {
    values.replace(rexval, (_, qouted: string, simple: string) => {
      if (qouted) {
        const deserialized = deserialize(qouted);
        deserialized && parsedQuery.push([group, deserialized]);
      }
      if (simple) parsedQuery.push([group, simple]);
      return '';
    });
    return '';
  });
  return parsedQuery;
};

export const parseSearchQuery = (query: string | null, options: Option[], decodeURI?: boolean): Option[] => {
  if (!query) return [];

  return parseQuery(query)
    .map(([group, value]) => {
      if (group === 'sa') {
        group = 'specialArrangement';
        value = value === 'y' ? 'special_arrangement' : value === 'n' ? 'not_special_arrangement' : '';
      }
      if (group === 'isNew') {
        value = value === 'y' ? 'new' : value === 'n' ? 'old' : '';
      }
      return options.find((o) => o.group === group && o.value === (decodeURI ? decodeURIComponent(value) : value));
    })
    .filter<Option>((o): o is Option => !!o);
};

export const parseDateQuery = (query: string | null): Tab | null => {
  if (!query) return null;

  const tab: [string, string][] = parseQuery(query)
    .map(([group, value]): [string, string] => [group, value])
    .filter(([group]) => group === 'id');

  const result: [string, Date][] = parseQuery(query)
    .map(([group, value]): [string, Date] => [group, new Date(value)])
    .filter(([group, value]) => (group === 'from' || group === 'to') && !isNaN(value.getTime()))
    .sort((a, b) => a[1].getTime() - b[1].getTime());

  if (result.length === 0) return null;
  if (result.length === 1) return { from: result[0][1], to: null, id: tab[0][1] };
  return { from: result[0][1], to: result[1][1], id: tab[0][1] };
};

export const buildDateQuery = (selectedRange: Tab): string | null => {
  const { from, to, id } = selectedRange;

  if (!from && !to) return null;

  const result = [];
  from && result.push(['from', serialize(format(from))]);
  to && result.push(['to', serialize(format(to))]);
  id && result.push(['id', id]);
  return result.map((e) => e.join('.')).join('*');
};

export const buildQuery = (selected: Option[]): string | null => {
  if (!selected.length) return null;

  const grouped = selected.reduce<Record<string, Option[]>>((acc, item) => {
    (acc[item.group] = acc[item.group] || []).push(item);
    return acc;
  }, {});

  const result = [];
  const keys = ['product', 'sitting', 'KAD', 'download', 'article', 'supervisors'];
  for (const key in grouped) {
    if (keys.indexOf(key) > -1) result.push([key, ...grouped[key].map((o) => o.value)]);
    if (key === 'specialArrangement')
      result.push(['sa', ...grouped[key].map((o) => (o.value === 'special_arrangement' ? 'y' : 'n'))]);
    if (key === 'isNew') result.push(['isNew', ...grouped[key].map((o) => (o.value === 'new' ? 'y' : 'n'))]);
  }

  return result.map((e) => e.join('.')).join('*');
};

export const buildFilter = (selected: Option[], selectedRange: Tab | null): ((sessions: Session[]) => Session[]) => {
  if (!selected.length && !selectedRange) return defaultFilter;

  type F = (session: Session) => boolean;

  const filters: F[] = [];

  if (selected.length) {
    const { product, sitting, specialArrangement, isNew } = selected.reduce<Record<string, Option[]>>((acc, item) => {
      (acc[item.group] = acc[item.group] || []).push(item);
      return acc;
    }, {});

    if (specialArrangement && specialArrangement.length === 1) {
      const value = specialArrangement[0].value === 'special_arrangement';
      filters.push((session) => session.article.specialArrangement === value);
    }

    if (sitting) {
      const list = sitting.map((option) => option.value);
      filters.push((session) => list.includes(session.sitting));
    }

    if (product) {
      const list = product.map((option) => option.value);
      filters.push((session) => list.includes(session.article.pid));
    }

    if (isNew) {
      const list = isNew.map((option) => option.value);
      const map: IsNewMap = {
        false: 'old',
        true: 'new',
      };
      filters.push((session) => list.includes(map[session.isNew.toString()]));
    }
  }

  if (selectedRange) {
    const { from, to, id } = selectedRange;
    if (from && to) {
      if (id === 'date') {
        filters.push(({ date }) => date >= from && date <= to);
      }

      if (id === 'available') {
        filters.push(({ available }) => !(available.to < from || available.from > to));
      }
    }

    if (from && !to) {
      if (id === 'date') {
        filters.push(({ date }) => date.getTime() === from.getTime());
      }

      if (id === 'available') {
        filters.push(({ available }) => available.from <= from && from <= available.to);
      }
    }
  }

  return (sessions) => filters.reduce((acc, filter) => acc.filter(filter), sessions);
};

export const buildSupervisorsSessionsFilter = (
  selected: Option[],
  selectedRange: Tab | null,
  sessions: SupervisorSession[],
): SupervisorSession[] => {
  if (!selected.length && !selectedRange) return defaultSupervisorFilter(sessions);

  type F = (session: SupervisorSession) => boolean;

  const filters: F[] = [];
  if (selected.length) {
    const { product, sitting, specialArrangement, supervisors } = selected.reduce<Record<string, Option[]>>(
      (acc, item) => {
        (acc[item.group] = acc[item.group] || []).push(item);
        return acc;
      },
      {},
    );

    if (specialArrangement && specialArrangement.length === 1) {
      const value = specialArrangement[0].value === 'special_arrangement';
      filters.push((session) => session.specialArrangement === value);
    }

    if (sitting) {
      const list = sitting.map((option) => option.value);
      filters.push((session) => list.includes(session.sitting));
    }

    if (product) {
      const list = product.map((option) => option.value);
      filters.push((session) => list.includes(hash(session.product)));
    }

    if (supervisors) {
      const list = supervisors.map((option) => option.value);
      filters.push((session) => {
        for (const supervisor of session.venueUsers) {
          if (list.includes(supervisor.fullName)) {
            return true;
          }
        }
        return false;
      });
    }
  }

  if (selectedRange) {
    const { from, to } = selectedRange;

    if (from && to) {
      filters.push(({ date }) => date >= from && date <= to);
    }

    if (from && !to) {
      filters.push(({ date }) => date.getTime() === from.getTime());
    }
  }

  return filters.reduce((acc, filter) => acc.filter(filter), sessions);
};
