export const safeDate = (input?: Date | string | number): Date => {
  const result = input ? new Date(input) : new Date();
  result.setUTCHours(12);
  return result;
};

export const getMonthStart = (date: Date): Date => {
  const result = safeDate(date);
  result.setUTCDate(1);
  return result;
};

export const getMonthEnd = (date: Date): Date => {
  const result = getMonthStart(date);
  result.setUTCMonth(result.getUTCMonth() + 1);
  result.setUTCDate(0);
  return result;
};

export const addMonth = (date: Date, amount: number): Date => {
  const result = safeDate(date);
  result.setUTCMonth(result.getUTCMonth() + amount);
  return result;
};

export const substractMonth = (date: Date, amount: number): Date => {
  const result = safeDate(date);
  result.setUTCMonth(result.getUTCMonth() - amount);
  return result;
};

export const getDay = (date: Date): number => (date.getDay() + 6) % 7;

export const getWeekStart = (date: Date): Date => {
  const result = safeDate(date);
  result.setUTCDate(result.getUTCDate() - getDay(result));
  return result;
};

export const getWeekEnd = (date: Date): Date => {
  const result = safeDate(date);
  result.setUTCDate(result.getUTCDate() + (6 - getDay(result)));
  return result;
};

export const toDateString = (date: Date): string => date.toISOString().slice(0, 10);

export const coords2date = (view: Date, [row, column]: [number, number]): Date => {
  const mstart = getMonthStart(view);
  const mend = getMonthEnd(view);
  const dcount = getDay(mstart) + mend.getUTCDate();
  const minRow = column < getDay(mstart) ? 1 : 0;
  const maxRow = (column > getDay(mend) ? Math.floor(dcount / 7) : Math.ceil(dcount / 7)) - 1;
  const nrow = Math.min(Math.max(row, minRow), maxRow);

  const start = getWeekStart(mstart);
  start.setUTCDate(start.getUTCDate() + nrow * 7 + column);
  return start;
};

export const date2coords = (value: Date): [number, number] => {
  const mstart = getMonthStart(value);
  const row = Math.floor((value.getUTCDate() + getDay(mstart) - 1) / 7);
  return [row, getDay(value)];
};
