import { createContext, FC, useContext, useMemo } from 'react';
import { childTestID } from '../../util/test-id';
import { safeDate, toDateString, getMonthEnd, getMonthStart, addMonth, substractMonth } from './date-util';
import { SelectionMonthYear } from './SelectionMonthYear';
import { CalendarCellProps, CalendarContextProps, CalendarProps, CalendarSelection } from './types';
import { useEventHandler } from './useEventHandler';
import { useRenderRows } from './useRenderRows';
import { Cell, Container, Table } from './views';

const CalendarContext = createContext<CalendarContextProps>({
  active: toDateString(safeDate()),
  selected: [null, null],
  on: {
    onClick: () => {},
    onKeyDown: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
  },
});

export const Calendar: FC<CalendarProps> = ({
  view,
  active,
  intermediate,
  selected,
  onActiveChange,
  onSelectedChange,
  onActiveMove,
  onIntermediateChange,
  onNextMonth,
  onPreviousMonth,
  onMonthChange,
  onYearsChange,
  pastMonthsCount,
  futureMonthsCount,
  initialView,
  noFutureDates,
  maxSpanOfDaysBetweenDates,
  yearFrom,
  ...rest
}) => {
  const activeValue = useMemo(() => toDateString(active), [active]);
  const intermediateValue = useMemo(() => intermediate && toDateString(intermediate), [intermediate]);
  const viewValue = useMemo(() => toDateString(view).slice(0, 7) + '-01', [view]);
  const selectValue = useMemo((): CalendarSelection => {
    const result: CalendarSelection = [
      selected[0] && toDateString(selected[0]),
      selected[1] && toDateString(selected[1]),
    ];
    if (!result[0] && result[1]) return [result[1], result[0]];
    if (result[0] && result[1]) return result.sort();
    return result;
  }, [selected]);

  const pastEndDate = useMemo(() => toDateString(getMonthStart(substractMonth(initialView, pastMonthsCount))), [
    initialView,
    pastMonthsCount,
  ]);

  const futureEndDate = useMemo(() => toDateString(getMonthEnd(addMonth(initialView, futureMonthsCount))), [
    initialView,
    futureMonthsCount,
  ]);

  const on = useEventHandler({
    onActiveChange,
    onSelectedChange,
    onActiveMove,
    onIntermediateChange,
    selectValue,
    pastEndDate,
    futureEndDate,
  });
  const value = useMemo(() => ({ active: activeValue, selected: selectValue, intermediate: intermediateValue, on }), [
    activeValue,
    intermediateValue,
    on,
    selectValue,
  ]);

  return (
    <Container {...rest}>
      <SelectionMonthYear
        view={view}
        onNextMonth={onNextMonth}
        onPreviousMonth={onPreviousMonth}
        onMonthChange={onMonthChange}
        onYearsChange={onYearsChange}
        pastMonthsCount={pastMonthsCount}
        futureMonthsCount={futureMonthsCount}
        initialView={initialView}
        yearFrom={yearFrom}
        noFutureDates={noFutureDates}
        testID={childTestID(rest.testID, 'selection')}
      />
      <CalendarContext.Provider value={value}>
        <Table testID={childTestID(rest.testID, 'table')}>
          {useRenderRows(viewValue, CalendarCell, selected, noFutureDates, maxSpanOfDaysBetweenDates, rest.testID)}
        </Table>
      </CalendarContext.Provider>
    </Container>
  );
};

const CalendarCell: FC<CalendarCellProps> = ({ value, ...rest }) => {
  const { active, selected, intermediate, on } = useContext(CalendarContext);
  const date = useMemo(() => safeDate(value), [value]);
  const selTo = selected[0] && (selected[1] || intermediate || active);
  const sel: CalendarSelection = [selected[0], selTo];
  if (sel[0] && sel[1]) sel.sort();

  const drawSel = !!sel[0] && sel[0] !== sel[1];
  const selectionStart = drawSel && sel[0] === value;
  const selectionEnd = drawSel && sel[1] === value;
  const selectionMiddle = drawSel && !!sel[0] && !!sel[1] && sel[0] < value && sel[1] > value;

  return (
    <Cell
      {...on}
      data-date={value}
      active={active === value}
      selected={selected[0] === value || selected[1] === value}
      selectionStart={selectionStart}
      selectionEnd={selectionEnd}
      selectionMiddle={selectionMiddle}
      {...rest}
    >
      {date.getDate()}
    </Cell>
  );
};
