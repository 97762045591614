import { FC, useMemo } from 'react';
import { childTestID } from '../../util/test-id';
import { getDay, getMonthEnd, getMonthStart, getWeekEnd, getWeekStart, safeDate, toDateString } from './date-util';
import { CalendarSelected } from './types';

export const useRenderRows = (
  viewValue: string,
  Cell: FC<{ value: string; disabled?: boolean; testID?: string }>,
  selected?: CalendarSelected,
  noFutureDates?: boolean,
  maxSpanOfDaysBetweenDates?: number,
  testID?: string,
): JSX.Element[] => {
  return useMemo(() => {
    const view = safeDate(viewValue);
    const monthStart = getMonthStart(view);
    const monthEnd = getMonthEnd(view);
    const start = getWeekStart(monthStart);
    const end = getWeekEnd(monthEnd);
    const result: JSX.Element[][] = [];
    const viewMonth = view.getMonth();

    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      const day = getDay(d);
      if (!day) result.push([]);
      const row = result[result.length - 1];

      let disabled = false;
      const refDate = toDateString(new Date());
      const today = safeDate(refDate);

      if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
        const firstDate = safeDate(selected[0]);
        const date = safeDate(d);

        const diff = Math.abs(firstDate.getTime() - date.getTime());
        const differenceInDays = Math.ceil(diff / (1000 * 3600 * 24));

        disabled = Boolean(noFutureDates && (+d > +today || differenceInDays >= maxSpanOfDaysBetweenDates));
      } else {
        disabled = Boolean(noFutureDates && +d > +today);
      }

      row[day] =
        d.getMonth() === viewMonth ? (
          <Cell
            key={day}
            value={toDateString(d)}
            disabled={disabled}
            testID={childTestID(testID, `date-${toDateString(d)}`)}
          />
        ) : (
          <td key={day} />
        );
    }

    return result.map((row, key) => (
      <tr key={key} role="row">
        {row}
      </tr>
    ));
  }, [viewValue, maxSpanOfDaysBetweenDates, selected, Cell, testID, noFutureDates]);
};
