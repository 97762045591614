import { Dispatch, useEffect } from 'react';
import { Action } from './types';

export const useFocusHandler = (
  input: React.RefObject<HTMLInputElement>,
  visible: boolean,
  dispatch: Dispatch<Action>,
): void => {
  useEffect(() => {
    const inputElement = input.current;
    if (inputElement && visible) {
      const focusin = () => {
        dispatch(['FOCUS_IN']);
      };

      inputElement.addEventListener('focusin', focusin);
      return () => inputElement.removeEventListener('focusin', focusin);
    }
  }, [dispatch, input, visible]);

  useEffect(() => {
    const inputElement = input.current;
    if (inputElement && visible) {
      const focusout = () => {
        dispatch(['FOCUS_OUTSIDE']);
      };

      inputElement.addEventListener('focusout', focusout);
      return () => inputElement.removeEventListener('focusout', focusout);
    }
  }, [dispatch, input, visible]);
};
