import { FC, Fragment, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useBootState, useIsHelpdesk, useIsInternalCompliance } from './boot';
import { HomePageNoCentre, useCentres } from './centres';
import { LoadingState } from './components/CircularProgress';
import { Content, Layout, PageHeader } from './components/Layout';
import { SupervisorsHeaderContainer } from './containers/Layout';
import { Notifications } from './notifications';
import { Route, useRoute } from './Routes';
import { CentreSessionsTable, useSessions } from './sessions';
import { SessionsFilter } from './sessions/views';
import { InternalServerErrorPage, NotFoundPage, NoAccessPage, ReportsPage, useMainHeader } from './pages';
import { useUserInfo } from './userSession/UserInfoProvider';
import { EulaPage } from './pages/eula';
import { useSupervisors, useSupervisorsSessions, SupervisorsPage } from './pages/supervisors';
import { useEula } from './userSession/EulaProvider';

const headers = {
  [Route.HOME]: null,
  [Route.REPORTS]: (
    <PageHeader title="File downloads report" testID="page-title" titleProps={{ id: 'reports-page-title' }} />
  ),
  [Route.SUPERVISORS]: <SupervisorsHeaderContainer />,
  [Route.CENTRE]: (
    <PageHeader title="Upcoming Sessions" testID="page-title" titleProps={{ id: 'upcoming-sessions-title' }} />
  ),
  [Route.NOT_FOUND]: null,
  [Route.NO_ACCESS]: null,
  [Route.EULA]: (
    <PageHeader
      centered
      title="Terms and Conditions"
      testID="page-title"
      titleProps={{ id: 'terms-and-conditions-title' }}
    />
  ),
  [Route.INTERNAL_ERROR]: null,
};

const homepageContent = (
  <Fragment>
    <HomePageNoCentre />
  </Fragment>
);

const centreContent = (
  <Fragment>
    <SessionsFilter />
    <CentreSessionsTable />
  </Fragment>
);

const supervisorsContent = <SupervisorsPage />;

const reportsContent = (
  <Fragment>
    <ReportsPage />
  </Fragment>
);

const eulaContent = <EulaPage />;

export const App: FC = () => {
  const boot = useBootState();
  const centres = useCentres();
  const sessions = useSessions();
  const isHelpdesk = useIsHelpdesk();
  const vusessions = useSupervisorsSessions();
  const supervisors = useSupervisors();
  const userInfo = useUserInfo();
  const { pending: eulaPending } = useEula();
  const isInternalCompliance = useIsInternalCompliance();

  const canAccessReports = useMemo(() => isHelpdesk || isInternalCompliance, [isInternalCompliance, isHelpdesk]);

  const supervisorsError = useMemo(() => vusessions.error || supervisors.error, [supervisors.error, vusessions.error]);
  const route = useRoute();
  const history = useHistory();

  let active: Route;

  switch (userInfo.error) {
    case '500':
      active = Route.INTERNAL_ERROR;
      break;
    default:
      switch (centres.error) {
        case 'failed_to_load_customers':
          active = Route.INTERNAL_ERROR;
          break;
        default:
          switch (route[0]) {
            case Route.CENTRE:
              switch (sessions.error) {
                case 'failed_to_load_sessions':
                  active = Route.INTERNAL_ERROR;
                  break;
                case 'customer_not_found':
                  active = Route.NOT_FOUND;
                  break;
                case 'no_access_to_sessions':
                  active = Route.NO_ACCESS;
                  break;
                default:
                  active = Route.CENTRE;
              }
              break;
            case Route.SUPERVISORS:
              switch (supervisorsError) {
                case '500':
                  active = Route.INTERNAL_ERROR;
                  break;
                case '404':
                  active = Route.NOT_FOUND;
                  break;
                case '403':
                  active = Route.NO_ACCESS;
                  break;
                default:
                  active = Route.SUPERVISORS;
              }
              break;
            default:
              active = route[0];
          }
      }
  }

  const appBar = useMainHeader('app-bar', active);
  const header = !isHelpdesk && !isInternalCompliance && active === Route.REPORTS ? null : headers[active];

  useEffect(
    () =>
      history.listen((_, action) => {
        if (action !== 'POP') {
          window.scrollTo(0, 0);
        }
      }),
    [history],
  );

  const loading =
    eulaPending || boot.loading || userInfo.loading || centres.loading || sessions.loading || vusessions.loading;
  const redirectToEULA =
    !boot.loading && !!boot.token && !eulaPending && !userInfo.loading && !userInfo.error && !userInfo.agreedToEULA;

  useEffect(() => {
    if (redirectToEULA && active !== Route.EULA) {
      history.replace('/eula');
    }
  }, [active, history, redirectToEULA]);

  const redirect = (active === Route.HOME && centres.selectedCentre) || null;

  useEffect(() => {
    if (redirect) {
      history.replace('/centre/' + redirect.id);
    }
  }, [history, redirect]);

  if (!boot.loading && boot.error) {
    return (
      <Layout>
        {appBar}
        <InternalServerErrorPage />
      </Layout>
    );
  }

  return (
    <Layout>
      {appBar}
      <Notifications />
      {!loading && header}
      <Content>
        {(loading || redirect) && <LoadingState testID="circular-progress" />}
        {!loading && active === Route.NOT_FOUND && <NotFoundPage />}
        {!loading && active === Route.NO_ACCESS && <NoAccessPage />}
        {!loading && active === Route.INTERNAL_ERROR && <InternalServerErrorPage />}
        {!loading && !redirect && active === Route.HOME && homepageContent}
        {!loading && active === Route.CENTRE && centreContent}
        {!loading && active === Route.SUPERVISORS && supervisorsContent}
        {!loading && active === Route.EULA && eulaContent}
        {!loading && active === Route.REPORTS && canAccessReports
          ? reportsContent
          : !canAccessReports && !loading && active === Route.REPORTS && <NoAccessPage />}
      </Content>
    </Layout>
  );
};
