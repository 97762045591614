import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { useBootState, useUserEmailHmac } from '../boot';
import { Route, useRoute } from '../Routes';
import { useProvider } from '../util/useProvider';
import { Centre, Result, useAuthRequest } from '../api';
import { AxiosRequestConfig } from 'axios';
import { Falsy } from 'react-hooks-async';
import { useUserInfo } from '../userSession';

const useCustomers = (run: boolean): Result<Centre[]> => {
  const params = useMemo((): AxiosRequestConfig | Falsy => run && { url: '/customers' }, [run]);
  return useAuthRequest<Centre[]>(params);
};

type CustomerError = 'failed_to_load_customers';

interface CentresContextProps {
  centresList: Centre[] | null;
  error: CustomerError | null;
  selectedCentre: Centre | null;
  demoCentre: Centre;
  loading: boolean;
}

const initialDemoCentre: Centre = {
  id: 'DEMO',
  name: 'DEMO',
  roles: [],
};

const Context = createContext<CentresContextProps>({
  error: null,
  centresList: null,
  selectedCentre: null,
  demoCentre: initialDemoCentre,
  loading: false,
});

export const useCentres = (): CentresContextProps => useContext(Context);

const getSelectedCentre = (hmac: string): Centre | null => {
  try {
    const selectedCentre = sessionStorage.getItem(hmac);
    return selectedCentre && JSON.parse(selectedCentre);
  } catch (error) {
    return null;
  }
};

const setSelectedCentre = (hmac: string, centre: Centre): void => sessionStorage.setItem(hmac, JSON.stringify(centre));

const clearSelectedCentre = (hmac: string): void => sessionStorage.removeItem(hmac);

export const isValidCentre = (centre: Centre, centreList: Centre[]): boolean =>
  centreList.some((c) => c.id === centre.id);

export const CentresProvider: FC = ({ children }) => {
  const route = useRoute();
  const boot = useBootState();
  const userInfo = useUserInfo();
  const { dispatch } = boot;
  const user = !boot.loading && userInfo.agreedToEULA && boot.user;

  const { result, pending, error: cerror } = useCustomers(!!user);

  const selected = useMemo(
    () => ((route[0] === Route.CENTRE || route[0] === Route.SUPERVISORS) && route[1].params.id) || null,
    [route],
  );

  const loading = boot.loading || (!!user && pending);

  const emailHmac = useUserEmailHmac();
  const hmac = useMemo(() => (user && emailHmac) || null, [emailHmac, user]);

  const lastSelectedCentre = useMemo(() => (user && user.lastSelectedCentre) || null, [user]);

  useEffect(() => {
    if (cerror && 'isAxiosError' in cerror && cerror.response) {
      if (cerror.response.status === 401) dispatch(['RESPONSE_401', cerror]);
      if (cerror.response.status === 403) dispatch(['RESPONSE_403', cerror]);
    }
  }, [cerror, dispatch]);

  const error: CustomerError | null = useMemo(() => (!loading && cerror && 'failed_to_load_customers') || null, [
    cerror,
    loading,
  ]);

  const centresList = useMemo(() => result || [], [result]);
  const demoCentre = useMemo(() => centresList.find((c) => c.id === initialDemoCentre.id) || initialDemoCentre, [
    centresList,
  ]);

  const firstCentre = useMemo(() => centresList.find((c) => c.id !== demoCentre.id) || null, [
    demoCentre.id,
    centresList,
  ]);

  const selectedCentre = useMemo(() => {
    if (loading || !hmac) return null;
    const storedCentre = getSelectedCentre(hmac);
    const selectedCentre = centresList?.find((centre) => centre.id === selected) || null;

    if (selected) {
      if (selectedCentre) {
        setSelectedCentre(hmac, selectedCentre);
        return selectedCentre;
      }
      return null;
    }

    if (!firstCentre) {
      clearSelectedCentre(hmac);
      return null;
    }

    if (storedCentre && isValidCentre(storedCentre, centresList)) return storedCentre;

    if (lastSelectedCentre && isValidCentre(lastSelectedCentre, centresList)) {
      setSelectedCentre(hmac, lastSelectedCentre);

      return lastSelectedCentre;
    }

    setSelectedCentre(hmac, firstCentre);
    return firstCentre;
  }, [centresList, firstCentre, hmac, lastSelectedCentre, loading, selected]);

  const value = useMemo<CentresContextProps>(() => ({ loading, error, centresList, selectedCentre, demoCentre }), [
    error,
    centresList,
    selectedCentre,
    demoCentre,
    loading,
  ]);

  return useProvider(Context, value, children);
};
