import { createRef, MutableRefObject, useMemo, useRef } from 'react';
import { Action, ReadCentres, ReducerState } from './types';

const refs = (): ReducerState['refs'] => ({ input: createRef() });

const defaultReducerState: ReducerState = {
  input: '',
  centres: () => [],
  selected: null,
  focused: false,
  list: [],
  refs: refs(),
};

export const useReducerState = (): MutableRefObject<ReducerState> =>
  useRef(useMemo(() => ({ ...defaultReducerState, refs: refs() }), []));

type H<P> = (state: ReducerState, payload: P) => ReducerState;

const centresChange: H<ReadCentres> = (state, payload) => {
  const { input } = state;
  const list = payload(input);
  return { ...state, centres: payload, list };
};

const inputChange: H<string> = (state, input): ReducerState => {
  const { centres } = state;
  const list = centres(input);
  return { ...state, input, list };
};

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  const { focused } = state;

  switch (action[0]) {
    case 'FOCUS_IN':
      return focused ? state : { ...state, focused: true };

    case 'FOCUS_OUTSIDE':
      return !focused ? state : { ...state, focused: false };

    case 'SELECTED_CHANGE':
      return { ...state, selected: action[1] };

    case 'CENTRES_CHANGE':
      return centresChange(state, action[1]);

    case 'INPUT_CHANGE':
      return inputChange(state, action[1]);

    case 'CLEAR_INPUT':
      return inputChange(state, '');
  }
};
