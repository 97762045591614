import { useMemo } from 'react';
import { CentreLink } from './views';
import { Centre } from './types';

export interface RenderOptionsProps {
  id: string;
  testID?: string;
}

export const useRenderOptions = (
  options: Centre[],
  selected: Centre | null,
  { id, testID }: RenderOptionsProps,
): JSX.Element[] =>
  useMemo(
    () =>
      options.map((option) => (
        <CentreLink
          key={option.id}
          id={id}
          testID={testID}
          centreId={option.id}
          label={`${option.id} ${option.name}`}
          selected={!!selected && option.id === selected.id}
          tabIndex={-1}
        />
      )),
    [id, options, selected, testID],
  );
