import { useEffect } from 'react';
import { Dispatch, StateResult } from './types';

export const useFocusHandler = (state: StateResult, dispatch: Dispatch): void => {
  const { container, input } = state.current.refs;

  useEffect(() => {
    const containerElement = container.current;

    if (containerElement) {
      const focusin = (event: FocusEvent) => {
        const related = event.relatedTarget as HTMLElement | null;
        if (!related || !containerElement.contains(related)) {
          dispatch(['FOCUS_IN']);
        }
      };

      containerElement.addEventListener('focusin', focusin);
      return () => containerElement.removeEventListener('focusin', focusin);
    }
  }, [dispatch, container, input]);

  useEffect(() => {
    const containerElement = container.current;
    if (containerElement) {
      const focusout = (event: FocusEvent) => {
        const related = event.relatedTarget as HTMLElement | null;
        if (!related || !containerElement.contains(related)) {
          dispatch(['FOCUS_OUTSIDE']);
        }
      };

      containerElement.addEventListener('focusout', focusout);
      return () => containerElement.removeEventListener('focusout', focusout);
    }
  }, [dispatch, container, input]);
};
