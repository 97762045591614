import { FC, Fragment, MouseEvent, useCallback, useMemo } from 'react';
import { childTestID, WithIProps } from '../../util/test-id';
import { CalendarPair, CalendarSelected } from '../Calendar';
import { TabButton, TabList, TabPanel } from './tabs';
import { Tab, TabInformation, Range } from './types';

interface CalendarTabsContainerProps extends WithIProps<'div'> {
  id: string;
  tabs: TabInformation[];
  selectedTab: Tab | null;
  activeTabPanel: string;
  onSelectedChange: (selected: CalendarSelected) => void;
  onTabSelect: (id: string) => void;
  range: Range;
  noFutureDates?: boolean;
  maxSpanOfDaysBetweenDates?: number;
  yearFrom?: Date;
}

const selectedDefault: CalendarSelected = [null, null];

export const CalendarTabsContainer: FC<CalendarTabsContainerProps> = ({
  tabs,
  selectedTab,
  onSelectedChange,
  onTabSelect,
  activeTabPanel,
  id,
  testID,
  range,
  noFutureDates,
  maxSpanOfDaysBetweenDates,
  yearFrom,
  ...rest
}) => {
  const from = useMemo(() => selectedTab && selectedTab.from, [selectedTab]);
  const to = useMemo(() => selectedTab && selectedTab.to, [selectedTab]);
  const selected = useMemo((): CalendarSelected => [from, to], [from, to]);

  const onKeyDown = useCallback(
    (event: any) => {
      const { key } = event;
      if (key === 'ArrowRight') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTabPanel, 1);
        onTabSelect(nActiveTab);
      }

      if (key === 'ArrowLeft') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTabPanel, -1);
        onTabSelect(nActiveTab);
      }
    },
    [activeTabPanel, onTabSelect, tabs],
  );

  const onTabClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { tabid } = event.currentTarget.dataset;
      tabid && onTabSelect(tabid);
    },
    [onTabSelect],
  );

  if (tabs.length === 1) {
    const tab = tabs[0];
    return (
      <CalendarPair
        testID={childTestID(testID, `${tab.id}-calendar`)}
        selected={selected}
        onSelectedChange={onSelectedChange}
        range={range}
        noFutureDates={noFutureDates}
        maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
        yearFrom={yearFrom}
        {...rest}
      />
    );
  }

  return (
    <Fragment>
      <TabList aria-label="Date range search options" testID={childTestID(testID, 'tab-List')}>
        {tabs.map((tab) => (
          <TabButton
            key={tab.id}
            group={id}
            tabId={tab.id}
            testID={childTestID(testID, `tab-${tab.id}`)}
            active={activeTabPanel === tab.id}
            onKeyDown={onKeyDown}
            onClick={onTabClick}
          >
            {tab.name}
          </TabButton>
        ))}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel key={tab.id} group={id} tabId={tab.id} active={tab.id === activeTabPanel}>
          <CalendarPair
            testID={childTestID(testID, `${tab.id}-calendar`)}
            selected={tab.id === selectedTab?.id ? selected : selectedDefault}
            onSelectedChange={onSelectedChange}
            range={range}
            noFutureDates={noFutureDates}
            maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
            {...rest}
          />
        </TabPanel>
      ))}
    </Fragment>
  );
};

const getTabId = (tabs: TabInformation[], activeTab: string, direction: -1 | 1): string => {
  const currentIndex = tabs.findIndex((tab) => tab.id === activeTab);
  const nextIndex = currentIndex + direction;
  if (nextIndex >= tabs.length) return tabs[0].id;
  if (nextIndex < 0) return tabs[tabs.length - 1].id;
  return tabs[nextIndex].id;
};
