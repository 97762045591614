import { useMemo } from 'react';
import { Route } from '../Routes';
import { AppBar, AppBarActions, AppBarTabs, AppBarTitle } from '../components/AppBar';
import { UserWidgetDropdown } from './containers';
import { AppTitle, SurveyButton, Tabs } from './views';
import { useIsAdmin, useIsHelpdesk } from '../boot';
import { useCentres } from '../centres';
import { MessagesCentre } from '../messageCentre';

export const useMainHeader = (testID?: string, activeRoute?: string): JSX.Element => {
  const { selectedCentre } = useCentres();
  const isHelpdesk = useIsHelpdesk();
  const isAdmin = useIsAdmin(selectedCentre && selectedCentre.id);
  const hasAccess = useMemo(() => isHelpdesk || isAdmin, [isHelpdesk, isAdmin]);

  return useMemo(
    () => (
      <AppBar testID={testID}>
        <AppBarTitle testID={testID}>
          <AppTitle href="/" />
        </AppBarTitle>
        <AppBarTabs>
          {hasAccess && activeRoute && selectedCentre && (
            <Tabs
              id="menubar"
              tabs={[
                {
                  id: Route.CENTRE,
                  name: 'Main view',
                  href: `/centre/${selectedCentre.id}`,
                },
                {
                  id: Route.SUPERVISORS,
                  name: ' Supervisors',
                  href: `/centre/${selectedCentre.id}/supervisors`,
                },
              ]}
              activeTabPanel={activeRoute}
              testID="menubar"
            />
          )}
        </AppBarTabs>
        <AppBarActions testID={testID}>
          <SurveyButton testID={testID} />
          <UserWidgetDropdown testID={testID} />
          <MessagesCentre testID={testID} />
        </AppBarActions>
      </AppBar>
    ),
    [testID, hasAccess, activeRoute, selectedCentre],
  );
};
