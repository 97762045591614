import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { childTestID } from '../../util/test-id';
import { Calendar } from './Calendar';
import { getMonthStart, safeDate, toDateString, addMonth, substractMonth } from './date-util';
import { CalendarPairProps } from './types';
import { usePairNavigation } from './usePairNavigation';
import { Group } from './views';

export const CalendarPair: FC<CalendarPairProps> = ({
  selected,
  onSelectedChange,
  range,
  noFutureDates,
  maxSpanOfDaysBetweenDates,
  yearFrom,
  ...rest
}) => {
  const nview = getMonthStart(safeDate());
  const [date, setDate] = useState(nview);
  const next = addMonth(date, 1);
  const initialNext = addMonth(nview, 1);
  const initialPrevious = substractMonth(date, 1);
  const [secondActive, setSecondActive] = useState(false);

  const currentDayDate = useMemo(() => {
    const newDate = secondActive ? safeDate(next) : safeDate(date);
    return new Date(safeDate().setUTCFullYear(newDate.getUTCFullYear(), newDate.getUTCMonth()));
  }, [date, secondActive, next]);
  const nextActive = useMemo(() => toDateString(safeDate(currentDayDate)), [currentDayDate]);
  const [active, setActive] = useState(() => safeDate(nextActive));
  const [intermediate, setIntermediate] = useState<Date>();
  const viewMount = date.getMonth();
  const onActiveMove = usePairNavigation(viewMount, selected, noFutureDates, maxSpanOfDaysBetweenDates, setActive);
  useEffect(() => {
    setActive(safeDate(nextActive));
  }, [nextActive]);

  const onNextMonth = useCallback(() => {
    setDate(addMonth(date, 1));
  }, [date]);

  const onPreviousMonth = useCallback(() => {
    if (yearFrom && substractMonth(date, 1) <= yearFrom) {
      return;
    }
    setDate(substractMonth(date, 1));
  }, [date, yearFrom]);

  const onPreviousMonthChange = useCallback(
    (month, year) => {
      if (noFutureDates) {
        month = String(Number(month) + 1);
      }
      const result = safeDate(date);
      result.setUTCFullYear(year, month);
      setSecondActive(false);
      setDate(result);
    },
    [date, noFutureDates],
  );

  const onNextMonthChange = useCallback(
    (month, year) => {
      if (noFutureDates) {
        month = String(Number(month) + 1);
      }
      const nextMonthDate = safeDate(date);
      nextMonthDate.setUTCFullYear(year, month);
      setSecondActive(true);
      setDate(substractMonth(nextMonthDate, 1));
    },
    [date, noFutureDates],
  );

  const onFirstYearsChange = useCallback((value) => {
    setDate(safeDate(value));
  }, []);

  const onSecondYearsChange = useCallback(
    (value) => {
      if (yearFrom && substractMonth(safeDate(value), 1) < yearFrom) {
        setDate(addMonth(safeDate(value), 1));
        return;
      }
      setDate(substractMonth(safeDate(value), 1));
    },
    [yearFrom],
  );

  return (
    <Group {...rest}>
      <Calendar
        testID={childTestID(rest.testID, 'from')}
        view={noFutureDates ? initialPrevious : date}
        initialView={noFutureDates ? initialPrevious : nview}
        active={active}
        intermediate={intermediate}
        selected={selected}
        onSelectedChange={onSelectedChange}
        onActiveChange={setActive}
        onIntermediateChange={setIntermediate}
        onActiveMove={onActiveMove}
        onPreviousMonth={onPreviousMonth}
        onMonthChange={onPreviousMonthChange}
        onYearsChange={onFirstYearsChange}
        pastMonthsCount={range.monthsBefore}
        futureMonthsCount={range.monthsAfter - 1}
        noFutureDates={noFutureDates}
        maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
        yearFrom={yearFrom}
      />
      <Calendar
        testID={childTestID(rest.testID, 'to')}
        view={noFutureDates ? date : next}
        active={active}
        intermediate={intermediate}
        selected={selected}
        onSelectedChange={onSelectedChange}
        onActiveChange={setActive}
        onIntermediateChange={setIntermediate}
        onActiveMove={onActiveMove}
        onNextMonth={onNextMonth}
        onMonthChange={onNextMonthChange}
        onYearsChange={onSecondYearsChange}
        pastMonthsCount={noFutureDates ? range.monthsBefore - 1 : range.monthsBefore}
        futureMonthsCount={range.monthsAfter - 1}
        initialView={noFutureDates ? nview : initialNext}
        noFutureDates={noFutureDates}
        maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
        yearFrom={yearFrom}
      />
      {rest.children}
    </Group>
  );
};
