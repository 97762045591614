import { createContext, FC, useContext, useMemo } from 'react';
import { useCurrentPage } from '../../components/Pagination';
import { useProvider } from '../../util/useProvider';
import { useSupervisorsSessions } from './SupervisorsSessionsProvider';
import { useSupervisorSessionFilter } from './SupervisorsSessionsFilterProvider';
import { buildSupervisorsSessionsFilter } from '../../sessions/session-filter-util';
import { useSessionSort } from '../../sessions/SessionSortProvider';
import { getSortedSupervisorSessions } from '../../sessions/sessions-sort-util';

interface ListContextProps {
  list: string[] | null;
  totalPages: number;
}

const ListContext = createContext<ListContextProps>({ totalPages: 0, list: null });

export const useSupervisorsSessionList = (): ListContextProps => useContext(ListContext);

export const SupervisorsSessionsListProvider: FC = ({ children }) => {
  const currentPage = useCurrentPage();
  const pageSize = 25;
  const sessions = useSupervisorsSessions();

  const {
    comboBox: { selected },
    datePicker: { selectedRange },
  } = useSupervisorSessionFilter();

  const {
    sort: { order },
  } = useSessionSort();

  const dataList = useMemo(
    () =>
      getSortedSupervisorSessions(
        buildSupervisorsSessionsFilter(selected, selectedRange, sessions.data ? [...sessions.data.sorted] : []),
        order,
      ),
    [sessions.data, selected, order, selectedRange],
  );

  const lastPage = (sessions.data && Math.ceil(dataList.length / pageSize)) || 1;
  const page = Math.min(currentPage, lastPage);

  const list = useMemo(
    () => dataList && dataList.map((list) => list.id).slice((page - 1) * pageSize, page * pageSize),
    [dataList, page],
  );

  const value = useMemo<ListContextProps>(() => ({ list, totalPages: lastPage }), [lastPage, list]);

  return useProvider(ListContext, value, children);
};
