import { FC } from 'react';
import { AnchorButton, AnchorButtonProps, Button } from '../components/Button';
import { childTestID } from '../util/test-id';
import { Bar, BarActions, BarContent } from '../components/Bar';
import clsx from 'clsx';
import { learnMoreButton } from './view.module.scss';

interface CookieConsentBarProps {
  link: string;
  text: string;
  onConsent: () => void;
  testID?: string;
}

export const CookieConsentBar: FC<CookieConsentBarProps> = ({ link, text, onConsent, testID }) => (
  <Bar testID={testID}>
    <BarContent testID={testID}>{text}</BarContent>
    <BarActions testID={testID}>
      <LearnMoreButton href={link} testID={testID} />
      <Button onClick={onConsent} testID={childTestID(testID, 'accept-button')}>
        Accept
      </Button>
    </BarActions>
  </Bar>
);

export const LearnMoreButton: FC<AnchorButtonProps> = ({ testID, className, ...rest }) => (
  <AnchorButton
    className={clsx(learnMoreButton, className)}
    variant="text"
    target="_blank"
    rel="noopener noreferrer"
    testID={`${testID}-learn-more-button`}
    {...rest}
  >
    Learn More
  </AnchorButton>
);
