import clsx from 'clsx';
import { FC, ReactNode, Ref, useEffect, useRef } from 'react';
import { TextSBold, TextSRegular, TextMRegular } from '../../theme/typography.module.scss';
import { dataTestID, WithIProps } from '../../util/test-id';
import { ButtonProps, IconButton, IconProps } from '../Button';
import { Close, Search } from '../Icon';
import {
  clear,
  container,
  contents,
  decor,
  empty,
  focused,
  group,
  groupLabel,
  hidden,
  icon,
  input,
  inputBox,
  inputLabel,
  option,
  screenReader,
  selectionGroup,
  selectionListBox,
  suggestionGroup,
  suggestionListBox,
  scrollableContents,
  hiddenLabel,
} from './FilterComboBox.module.scss';

export type StatusProps = WithIProps<'div'>;

export const Status: FC<StatusProps> = ({ className, testID, ...rest }) => (
  <div role="status" className={clsx(screenReader, className)} {...dataTestID(testID)} {...rest} />
);

export const AssistiveHint: FC<WithIProps<'span'>> = ({ className, testID, ...rest }) => (
  <span className={clsx(screenReader, className)} {...dataTestID(testID)} {...rest} />
);

export type EmptyMessageProps = WithIProps<'p'>;

export const EmptyMessage: FC<EmptyMessageProps> = ({ className, testID, ...rest }) => (
  <p className={clsx(empty, TextSRegular, className)} {...dataTestID(testID)} {...rest} />
);

export interface LastChangeProps extends WithIProps<'div'> {
  label?: ReactNode;
}

export const LastChange: FC<LastChangeProps> = ({ label = 'Last change:', className, testID, children, ...rest }) => (
  <div className={clsx(screenReader, className)} {...dataTestID(testID)} {...rest}>
    {label} <span aria-live="polite">{children}</span>
  </div>
);

export type ScreenReaderProps = WithIProps<'span'>;

export const ScreenReader: FC<InputViewProps> = ({ className, testID, ...rest }) => (
  <span className={clsx(screenReader, className)} {...dataTestID(testID)} {...rest} />
);

export interface InputViewProps extends WithIProps<'input'> {
  forwardRef?: Ref<HTMLInputElement>;
}

export const InputView: FC<InputViewProps> = ({ forwardRef, className, testID, ...rest }) => (
  <input
    ref={forwardRef}
    type="text"
    role="combobox"
    aria-autocomplete="list"
    autoComplete="off"
    className={clsx(input, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export interface InputLabelViewProps extends WithIProps<'label'> {
  visible?: boolean;
}

export const InputLabelView: FC<InputLabelViewProps> = ({ className, visible, testID, ...rest }) => (
  <label
    className={clsx(TextMRegular, { [hiddenLabel]: !visible, [inputLabel]: visible }, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export type InputContainerViewProps = WithIProps<'div'>;

export const InputContainerView: FC<InputContainerViewProps> = ({ className, testID, ...rest }) => (
  <div className={clsx(inputBox, className)} role="none" {...dataTestID(testID)} {...rest} />
);

export interface ContentProps extends WithIProps<'div'> {
  scrollableInput?: boolean;
}

export const Content: FC<ContentProps> = ({ scrollableInput, className, testID, ...rest }) => (
  <div
    className={clsx({ [contents]: !scrollableInput, [scrollableContents]: scrollableInput }, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export type DecorationProps = WithIProps<'span'>;

export const Decoration: FC<DecorationProps> = ({ className, testID, children, ...rest }) => (
  <span aria-hidden="true" className={clsx(decor, className)} {...dataTestID(testID)} {...rest}>
    <Search className={icon} />
    {children}
  </span>
);

export type ClearProps = IconProps<ButtonProps>;

export const Clear: FC<ClearProps> = ({ disabled, className, children, ...rest }) => (
  <IconButton
    variant="text"
    disabled={disabled}
    className={clsx(clear, { [hidden]: disabled }, className)}
    tabIndex={0}
    {...rest}
  >
    <Close />
    {children}
  </IconButton>
);

export interface ContainerViewProps extends WithIProps<'div'> {
  forwardRef?: Ref<HTMLDivElement>;
  contentProps?: ContentProps;
  before?: ReactNode;
  after?: ReactNode;
  focus?: boolean;
  scrollableInput?: boolean;
}

export const ContainerView: FC<ContainerViewProps> = ({
  forwardRef,
  contentProps,
  scrollableInput,
  before = <Decoration />,
  after,
  focus,
  className,
  testID,
  children,
  ...rest
}) => (
  <div
    ref={forwardRef}
    className={clsx(container, { [focused]: focus }, className)}
    role="search"
    tabIndex={-1}
    {...dataTestID(testID)}
    {...rest}
  >
    {before}
    <Content scrollableInput={scrollableInput} {...contentProps}>
      {children}
    </Content>
    {after}
  </div>
);

export interface GroupViewProps extends WithIProps<'ul'> {
  variant: 'selected' | 'suggested';
}

export const GroupView: FC<GroupViewProps> = ({ variant, testID, className, ...rest }) => (
  <ul
    role="group"
    className={clsx(
      group,
      { [selectionGroup]: variant === 'selected', [suggestionGroup]: variant === 'suggested' },
      className,
    )}
    {...dataTestID(testID)}
    {...rest}
  />
);

export type GroupLabelViewProps = WithIProps<'li'>;

export const GroupLabelView: FC<GroupLabelViewProps> = ({ testID, className, ...rest }) => (
  <li role="presentation" className={clsx(groupLabel, TextSBold, className)} {...dataTestID(testID)} {...rest} />
);

export interface OptionViewProps extends WithIProps<'li'> {
  selected?: boolean;
}

export const OptionView: FC<OptionViewProps> = ({ selected, className, testID, ...rest }) => {
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (selected && ref.current) {
      ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [selected]);

  return (
    <li
      ref={ref}
      role="option"
      className={clsx({ [focused]: selected }, TextSBold, option, className)}
      {...(selected ? { 'aria-selected': 'true' } : {})}
      {...dataTestID(testID)}
      {...rest}
    />
  );
};

export interface ListBoxViewProps extends WithIProps<'div'> {
  forwardRef?: Ref<HTMLDivElement>;
  variant: 'selected' | 'suggested';
  visible?: boolean;
}

export const ListBoxView: FC<ListBoxViewProps> = ({
  forwardRef,
  variant,
  visible = true,
  className,
  testID,
  ...rest
}) => (
  <div
    ref={forwardRef}
    role="listbox"
    className={clsx(
      { [selectionListBox]: variant === 'selected', [suggestionListBox]: variant === 'suggested', [hidden]: !visible },
      className,
    )}
    {...dataTestID(testID)}
    {...rest}
  />
);
