import clsx from 'clsx';
import { KeyboardEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { container, down, popover, up } from './DropDown.module.scss';

// FIXME: Use document.activeElement instead of event.relatedTarget in focusout event listener (timeout might be needed)

export const useFocusLeave = (element: HTMLElement | null, callback?: false | (() => void)): void => {
  useEffect(() => {
    if (!element || !callback) return;

    const handleEvent = (event: FocusEvent): void => {
      setTimeout(() => {
        if (!event.relatedTarget || !element.contains(event.relatedTarget as Node)) {
          callback();
        }
      }, 150);
    };

    element.addEventListener('focusout', handleEvent);
    return (): void => element.removeEventListener('focusout', handleEvent);
  }, [callback, element]);
};

interface PopoverBehaviour {
  containerClassName: string;
  popoverClassName: string;
}

type Direction = 'up' | 'down';

export const usePopoverBehaviour = (element: HTMLElement | null, open: boolean, downOnly = false): PopoverBehaviour => {
  const [direction, setDirection] = useState<Direction>('down');

  useLayoutEffect(() => {
    if (!element || !open) return;
    const menu = element.querySelector('[role="menu"]');
    if (!menu) return;
    const co = element.getBoundingClientRect();
    const po = menu.getBoundingClientRect();
    const wh = window.innerHeight;

    if (downOnly) {
      setDirection('down');
    } else {
      setDirection(wh >= co.bottom + po.height ? 'down' : 'up');
    }
  }, [element, open, downOnly]);

  return useMemo(
    () => ({
      containerClassName: container,
      popoverClassName: clsx(popover, direction === 'up' ? up : down),
    }),
    [direction],
  );
};

const findFocusIndex = (currentTarget: HTMLElement): [number, number] => {
  const items = currentTarget.querySelectorAll<HTMLAnchorElement>('[data-item="menuitem"]');
  const { activeElement } = document;
  for (let i = 0; i < items.length; i++) {
    if (items[i] === activeElement) {
      return [i, items.length];
    }
  }
  return [-1, items.length];
};

export interface MenuNavigationInput {
  container: HTMLElement | null;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export interface MenuNavigationOutput {
  selected: number;
  onKeyDown: (event: KeyboardEvent<HTMLElement>) => void;
}

export const useDropdownMenuNavigation = (input: MenuNavigationInput): MenuNavigationOutput => {
  const { open, container } = input;
  const [selected, setSelected] = useState(0);
  const [forceSelect, setForceSelect] = useState({});
  const inputRef = useRef(input);
  inputRef.current = input;

  useMemo(() => !open && setSelected(0), [open]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const { container, open, onOpen, onClose } = inputRef.current;
      if (!container) return;
      const { key } = event;
      if (!open) {
        if (key === 'ArrowDown') {
          event.preventDefault();
          setSelected(0);
          onOpen();
        }

        if (key === 'ArrowUp') {
          event.preventDefault();
          setSelected(-1);
          onOpen();
        }
      } else {
        if (key === 'Enter') {
          event.preventDefault();
          const target = event.target as HTMLElement;
          target.click();
        }

        if (key === 'Escape') {
          event.preventDefault();
          const button: HTMLButtonElement | null = container.querySelector('[aria-haspopup]');
          if (button) button.focus();
          onClose();
        }

        if (key === 'ArrowDown') {
          event.preventDefault();
          const [index, total] = findFocusIndex(container);
          if (index < 0) {
            // Focused item not found, force refocus
            setForceSelect({});
            setSelected(0);
          } else if (selected > index && index !== total - 1) {
            setForceSelect({});
            setSelected(index + 1);
          } else if (index !== total - 1) {
            setSelected(index + 1);
          }
        }

        if (key === 'ArrowUp') {
          event.preventDefault();
          const [index] = findFocusIndex(container);
          if (index < 0) {
            // Focused item not found, force refocus
            setForceSelect({});
            setSelected(-1);
          } else if (index !== 0) {
            setSelected(index - 1);
          }
        }
      }
    },
    [selected],
  );

  useEffect(() => {
    if (!open || !container || !forceSelect) return;
    // Delay focus to prevent jup on dropdown repositioning
    const timer = setTimeout(() => {
      const items = container.querySelectorAll<HTMLElement>('[data-item="menuitem"]');
      const l = items.length;
      if (!l) return;
      items[(l + selected) % l].focus();
    }, 0);
    return (): void => clearTimeout(timer);
  }, [container, forceSelect, selected, open]);

  return useMemo(() => ({ selected, onKeyDown }), [onKeyDown, selected]);
};
