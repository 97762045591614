import clsx from 'clsx';
import { FC } from 'react';
import { dataTestID, WithIProps, childTestID } from '../../util/test-id';
import { Button, ButtonProps } from '../Button';
import { DatePicker, DatePickerProps } from '../DatePicker';
import { ReportsFilterComboBox, ReportsFilterComboBoxProps, Article } from '../ReportsFilterComboBox';
import reportGraph from '../../public/reportGraph.svg';
import xls from '../../public/xls.svg';
import {
  input,
  datePicker,
  label,
  labelContainer,
  focusedContainer,
  graphImage,
  centreComboBox,
} from './views.module.scss';
import { FilterComboBox, FilterComboBoxProps, Option, Group, ReadOptions } from '../FilterComboBox';
import { ReadArticles } from '../ReportsFilterComboBox';

const ReportsFilterArticleComboBoxView: FC<ReportsFilterComboBoxProps> = ({ className, ...rest }) => (
  <ReportsFilterComboBox className={clsx(input, className)} {...rest} />
);

const ReportsFilterCentreComboBoxView: FC<FilterComboBoxProps> = ({ className, ...rest }) => (
  <FilterComboBox scrollableInput={true} className={clsx(centreComboBox, className)} {...rest} />
);

interface ReportsArticleFilter extends WithIProps<'input'> {
  selected: Article;
  focused: boolean;
  options: ReadArticles;
}

export const ReportsArticleFilter: FC<ReportsArticleFilter> = ({
  selected,
  focused,
  options,
  className,
  testID,
  ...rest
}) => (
  <ReportsFilterArticleComboBoxView
    id="reports-filter-component"
    testID={childTestID(testID, 'reports-filter')}
    selected={selected}
    articles={options}
    className={clsx(focused ? focusedContainer : '', className)}
    {...rest}
  />
);

interface ReportsCentreFilter extends WithIProps<'div'> {
  selected: Option[];
  options: ReadOptions;
  groups: Group[];
  onSelectionChange: (options: Option[]) => void;
}

export const ReportsCentreFilter: FC<ReportsCentreFilter> = ({
  selected,
  options,
  onSelectionChange,
  groups,
  testID,
  ...rest
}) => (
  <ReportsFilterCentreComboBoxView
    id="reports-filter-centres"
    testID={childTestID(testID, 'reports-centre-filter')}
    selected={selected}
    onSelectionChange={onSelectionChange}
    options={options}
    groups={groups}
    {...rest}
  />
);

export const LabelView: FC<WithIProps<'label'>> = ({ className, testID, ...rest }) => (
  <div className={labelContainer}>
    <label className={clsx(label, className)} {...dataTestID(testID)} {...rest} />
  </div>
);

export const DownloadReportButton: FC<ButtonProps> = ({ testID, className, ...rest }) => (
  <Button className={className} testID={`${testID}-download-report-button`} tabIndex={0} {...rest}>
    Download report
  </Button>
);

interface ReportsDatePickerViewProps extends DatePickerProps {
  noFutureDates?: boolean;
  maxSpanOfDaysBetweenDates?: number;
  yearFrom?: Date;
}

export const ReportsDatePickerView: FC<ReportsDatePickerViewProps> = ({
  noFutureDates,
  maxSpanOfDaysBetweenDates,
  yearFrom,
  className,
  ...rest
}) => (
  <DatePicker
    noFutureDates={noFutureDates}
    maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
    yearFrom={yearFrom}
    className={clsx(datePicker, className)}
    {...rest}
  />
);

export const GraphImage: FC<WithIProps<'img'>> = ({ testID, ...rest }) => (
  <img src={reportGraph} alt="Graph" className={graphImage} {...dataTestID(testID)} {...rest} />
);

export const XLSImage: FC<WithIProps<'img'>> = ({ testID, ...rest }) => (
  <img src={xls} alt="XLS" {...dataTestID(testID)} {...rest} />
);
