import { FC } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Alert } from '../components/Icon';
import { WithIProps, dataTestID } from '../util/test-id';
import { errorMark, notificationProgress, bar } from './views.module.scss';

export const ErrorIcon: FC = () => <Alert className={errorMark} />;

export const ProgressBar: FC<WithIProps<'div'>> = ({ className, testID }) => (
  <div className={clsx(notificationProgress, className)} {...dataTestID(testID, 'loading-bar')}>
    <div className={bar} />
  </div>
);

interface NotificationWaitingMessageProps extends WithIProps<'span'> {
  product: string;
  sa: boolean;
  date: Date;
  sitting: string;
  format: string | undefined;
}

export const NotificationWaitingMessage: FC<NotificationWaitingMessageProps> = ({
  product,
  sa,
  date,
  sitting,
  format,
}) => (
  <span>
    Your {format ? <b>{`.${format.toLowerCase()} `}</b> : ''}file for
    <b>
      {` ${product}`} {sa ? 'Special Arr. ' : ''}
    </b>
    on
    <b>
      {` ${dayjs(date).format('DD MMM YYYY')}`} {`${sitting} `}
    </b>
    is being prepared. Download will start once preparation is complete.
  </span>
);

interface NotificationReportFailedProps extends WithIProps<'span'> {
  articleNumber?: string;
  centreIds?: string[];
  dateFrom: string;
  dateTo: string | null;
}

export const NotificationReportGenerationFailedMessage: FC<NotificationReportFailedProps> = ({
  articleNumber,
  centreIds,
  dateFrom,
  dateTo,
}) => {
  const centresRows = (): JSX.Element[] => {
    if (centreIds?.length) {
      return centreIds.map((id, index) => <b key={id}>{`${id}${index !== centreIds.length - 1 ? ',' : ''} `}</b>);
    }
    return [];
  };
  const SingleDateText = (
    <span>
      There was no download activity against {articleNumber ? <b>{`${articleNumber} `}</b> : centresRows()}on
      <b>{` ${dateFrom}`}</b>, please select another date range.
    </span>
  );
  const DoubleDateText = (
    <span>
      There was no download activity against {articleNumber ? <b>{`${articleNumber} `}</b> : centresRows()}
      from <b>{dateFrom}</b> to <b>{dateTo}</b>, please select another date range.
    </span>
  );

  return dateTo ? DoubleDateText : SingleDateText;
};
