import { AxiosError } from 'axios';
import { ParsedIdToken, Result, TokenResponse } from '../api';
import { createHmac } from 'crypto';

export type Action =
  | ['CONFIG_RESPONSE', Result<ConfigResponse>]
  | ['TOKEN_RESPONSE', Result<TokenResponse>]
  | ['REFRESH_TOKEN_RESPONSE', TokenResponse]
  | ['CLEAR_STATE']
  | ['RESPONSE_401', AxiosError]
  | ['RESPONSE_403', AxiosError];

export interface ConfigResponse {
  AUTH_API: string;
  AUTH_CLIENT_ID: string;
  ENV: string;
}

export interface Config {
  AUTH_API: string;
  AUTH_CLIENT_ID: string;
  ENV: string;
}

export interface State {
  config: Config | null;
  token: TokenResponse | null;
  user: null | ParsedIdToken;
  loading: boolean;
  redirect: string | null;
  code: string | null;
  error: null | string;
  codeRequest: string | null;
  locationRequest: string | null;
}

const fromURLSafe = (input: string) => input.replace(/-/g, '+').replace(/_/g, '/');
const base64URLDecode = (input: string) => Buffer.from(fromURLSafe(input), 'base64').toString('utf8');

const encodeToken = (data: any): string => btoa(JSON.stringify(data));

const decodeToken = <T>(token?: string | null): T | null => {
  if (token) {
    try {
      return JSON.parse(base64URLDecode(token));
    } catch (ex) {
      // void
    }
  }
  return null;
};

const readRedirect = () => {
  const params = new URLSearchParams(location.search);
  params.delete('code');
  params.delete('state');
  params.delete('error');
  params.delete('error_description');
  const search = String(params);
  return location.pathname + (search ? '?' : '') + search;
};

const scope = 'openid profile email orgs roles globalListening lastSelectedOrg';
const nonce = () => Math.random().toString(32).slice(2) + Date.now().toString(32);

const authParams = (client_id: string, state = false) => ({
  scope,
  nonce: nonce(),
  client_id,
  redirect_uri: location.origin,
  ...(state && { state: encodeToken({ redirect: readRedirect() }) }),
});

const initialState: State = {
  loading: true,
  config: null,
  redirect: null,
  token: null,
  code: null,
  codeRequest: null,
  locationRequest: null,
  error: null,
  user: null,
};

type AuthState = { redirect: string };

export const readInitialState = (): State => {
  try {
    const token = sessionStorage.getItem('token');
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    const desc = params.get('error_description');
    if (desc) console.error(desc);
    const code = params.get('code');
    const state = error || code ? decodeToken<AuthState>(params.get('state')) || { redirect: '/' } : null;
    const redirect = state && state.redirect;

    return { ...initialState, token: token && JSON.parse(token), error, code, redirect };
  } catch (error) {
    console.error(error);
  }
  return initialState;
};

const initAppState = (state: State): State => {
  const { token, code, config, codeRequest, error, loading, locationRequest } = state;

  if (error) {
    if (loading) return { ...state, loading: false };
    return state;
  }

  if (!config) return state;

  const { AUTH_CLIENT_ID, AUTH_API } = config;

  if (code) {
    if (codeRequest) return state;
    return {
      ...state,
      codeRequest: new URLSearchParams({
        ...authParams(AUTH_CLIENT_ID),
        grant_type: 'authorization_code',
        code,
      }).toString(),
    };
  }

  if (!token) {
    if (locationRequest) return state;
    const params = new URLSearchParams({
      ...authParams(AUTH_CLIENT_ID, true),
      response_type: 'code',
    });

    return { ...state, locationRequest: `${AUTH_API}/authorize?${params}` };
  }

  const parsed = decodeToken<ParsedIdToken>(token.id_token.split('.')[1]);
  const isHelpdesk = (parsed && parsed.userRoles && parsed.userRoles.some((r) => r === 'INTERNAL_HELPDESK')) || false;
  const isInternalCompliance =
    (parsed && parsed.userRoles && parsed.userRoles.some((r) => r === 'INTERNAL_COMPLIANCE')) || false;
  const emailHmac = parsed && parsed.email && createHmac('sha256', parsed.email).update('user').digest('hex');

  if (parsed) {
    parsed.isHelpdesk = isHelpdesk;
    parsed.isInternalCompliance = isInternalCompliance;
    parsed.emailHmac = emailHmac || undefined;
  }

  state = { ...state, user: parsed };

  if (!parsed) {
    return { ...state, error: 'invalid_token', loading: false };
  }

  if (parsed.exp * 1000 < Date.now()) {
    if (locationRequest) return state;
    const params = new URLSearchParams({
      ...authParams(AUTH_CLIENT_ID, true),
      response_type: 'code',
    });

    return { ...state, locationRequest: `${AUTH_API}/authorize?${params}` };
  }

  if (state.loading) {
    return { ...state, loading: false };
  }

  return state;
};

export const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'CONFIG_RESPONSE': {
      const [, payload] = action;
      if (payload.error) return { ...state, error: 'config_failed_to_load', loading: false };
      if (payload.result) return initAppState({ ...state, config: payload.result });
      return state;
    }

    case 'TOKEN_RESPONSE': {
      const [, { error, result }] = action;
      if (error) {
        if ('isAxiosError' in error) {
          const { response } = error;
          if (response) return { ...state, error: response.data.error, loading: false };
        }

        return { ...state, error: 'token_failed_to_load', loading: false };
      }

      if (result) {
        return initAppState({ ...state, token: result, code: null, codeRequest: null });
      }

      return state;
    }

    case 'REFRESH_TOKEN_RESPONSE': {
      const [, token] = action;
      return { ...state, token };
    }

    case 'CLEAR_STATE': {
      return { ...initialState, config: state.config, loading: false };
    }

    case 'RESPONSE_401':
      return initAppState({ ...state, token: null });

    case 'RESPONSE_403': {
      return initAppState({ ...state, token: null });
    }
  }
};
