import { FC, useMemo } from 'react';
import { ReportsPageLayout } from '../components/ReportsPageLayout';
import { useIsInternalCompliance } from '../boot';
import { useReports } from '../reports';

export const ReportsPage: FC = () => {
  const {
    articleComboBox: { selectedArticle },
    centresComboBox: { selectedCentres },
    datePicker: { selectedRange },
    tabId,
    onReportDownloadClick,
  } = useReports();

  const isInternalCompliance = useIsInternalCompliance();

  const disabledArticle = useMemo(
    () => tabId === 'ARTICLE' && (!selectedRange || !selectedArticle || !Object.keys(selectedArticle).length),
    [selectedRange, selectedArticle, tabId],
  );

  const disabledCentre = useMemo(
    () => tabId === 'CENTRE' && (!selectedRange || !selectedCentres || !selectedCentres.length),
    [selectedRange, selectedCentres, tabId],
  );

  return (
    <ReportsPageLayout
      buttonDisabled={disabledCentre || disabledArticle}
      isInternalCompliance={isInternalCompliance}
      onReportDownloadClick={onReportDownloadClick}
    />
  );
};
