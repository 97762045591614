import { createContext, FC, useContext, useMemo } from 'react';
import { useProvider } from '../util/useProvider';
import { AppBootResult, useAppBoot } from './useBoot';

export type BootContextProps = AppBootResult;

const Context = createContext<BootContextProps>({
  loading: true,
  dispatch: () => {
    throw new Error('Missing BootProvider');
  },
});

export const useBootState = (): BootContextProps => useContext(Context);

export const useIsHelpdesk = (): boolean => {
  const boot = useBootState();
  return (!boot.loading && boot.user && boot.user.isHelpdesk) || false;
};

export const useIsAdmin = (centreId: string | null): boolean => {
  const boot = useBootState();
  const roles =
    (centreId && !boot.loading && boot.user && boot.user.centres.find((c) => c.id === centreId)?.roles) || null;

  return (roles && roles.some((r) => r === 'DELEGATED_ADMIN' || r === 'ORG_ADMIN')) || false;
};

export const useIsInternalCompliance = (): boolean => {
  const boot = useBootState();
  return (!boot.loading && boot.user && boot.user.isInternalCompliance) || false;
};

export const useUserName = (): string | null => {
  const boot = useBootState();
  const token = (!boot.loading && boot.user) || null;
  return token && token.name;
};

export const useUserEmailHmac = (): string | null => {
  const boot = useBootState();
  const user = (!boot.loading && boot.user) || null;
  return (user && user.emailHmac) || null;
};

export const clearSessionStorageNotifications = (): void => {
  const notifications = Object.keys(sessionStorage).filter((key) => key.includes('notification-'));

  if (notifications && notifications.length > 0) {
    for (const key of notifications) {
      sessionStorage.removeItem(key);
    }
  }
};

export const useMyCambridgeLink = (): string => {
  interface Environment {
    [key: string]: string;
  }

  const envMap: Environment = {
    DEV: 'dev',
    'DEV-INFRA1': 'dev',
    'DEV-INFRA2': 'dev',
    QA: 'qa',
    'QA-TESTS': 'qa',
    DEMO: 'prep',
    PREP: 'prep',
  };

  const boot = useBootState();
  const config = (!boot.loading && boot.config) || null;
  const environment = !config?.ENV ? 'prod' : envMap[config.ENV.toUpperCase()] || 'dev';

  return environment !== 'prod'
    ? `https://mycambridge.${environment}.internal.sso.cambridge.org/`
    : 'https://mycambridge.sso.cambridge.org/';
};

export const useLogout = (): (() => void) | null => {
  const link = useMyCambridgeLink();

  const boot = useBootState();
  const config = (!boot.loading && boot.config) || null;
  const emailHmac = useUserEmailHmac();

  return useMemo(
    () =>
      config &&
      (() => {
        sessionStorage.removeItem('token');
        emailHmac && sessionStorage.removeItem(emailHmac);
        location.assign(link);
      }),
    [config, emailHmac, link],
  );
};

export const BootProvider: FC = ({ children }) => useProvider(Context, useAppBoot(), children);
