import { FC, useCallback, useState, useMemo } from 'react';
import { useBootState, useLogout, useIsHelpdesk, useIsInternalCompliance } from '../boot';
import { useCentres } from '../centres';
import { DropdownMenuContainer } from '../components/DropDown';
import { childTestID } from '../util/test-id';
import { CentresFilter } from './CentresFilter';
import { CentreLink } from '../components/CentreComboBox';
import {
  CenterListTitle,
  Separator,
  SupportButton,
  ReportsButton,
  UserWidget,
  UserWidgetDropDownMenu,
  UserAgreementButton,
  GoToMyCambridgeAction,
} from './views';

export const UserWidgetDropdown: FC<{ testID?: string }> = ({ testID, ...rest }) => {
  const buttonId = `user-widget-trigger`;
  const menuId = `user-widget-menu`;
  const centerListId = 'center-list-title';
  const userWidgetTestId = childTestID(testID, 'user-widget');

  const boot = useBootState();
  const user = (!boot.loading && boot.user) || null;

  const { selectedCentre, centresList, demoCentre } = useCentres();

  const isHelpdesk = useIsHelpdesk();
  const isInternalCompliance = useIsInternalCompliance();
  const canSeeReports = useMemo(() => isHelpdesk || isInternalCompliance, [isHelpdesk, isInternalCompliance]);

  const centreList = centresList || [];

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClick = useCallback(() => setOpen((value) => !value), []);
  const logout = useLogout();

  if (boot.loading) return null;

  return (
    <DropdownMenuContainer
      testID={childTestID(testID, `user-widget-dropdown`)}
      open={open}
      downOnly={true}
      onClose={onClose}
      onOpen={onOpen}
      {...rest}
    >
      <UserWidget
        userName={user?.name || ''}
        centre={selectedCentre || null}
        testID={testID}
        id={buttonId}
        onClick={onClick}
        active={open}
        aria-controls={menuId}
        aria-expanded={open}
        aria-haspopup="true"
        aria-label="open user widget dropdown"
      />

      <UserWidgetDropDownMenu
        visible={open}
        id={menuId}
        aria-labelledby={buttonId}
        testID={childTestID(testID, menuId)}
        items={[
          ...(centreList.length
            ? [
                <CenterListTitle id={centerListId} key="title" role="none" />,
                <CentreLink
                  testID={userWidgetTestId}
                  centreId={demoCentre.id}
                  label={`${demoCentre.id} ${demoCentre.name}`}
                  key={demoCentre.id}
                  selected={!!selectedCentre && selectedCentre.id === demoCentre.id}
                />,
                <CentresFilter key={'centres'} testID={userWidgetTestId} />,
                <Separator key="separator" role="separator" />,
              ]
            : []),
          ...(canSeeReports ? [<ReportsButton key="reports" testID={userWidgetTestId} />] : []),
          <SupportButton key="support" testID={userWidgetTestId} />,
          <UserAgreementButton key="eula" testID={userWidgetTestId} />,
          <GoToMyCambridgeAction key="go-to-my-cambridge" logout={logout} testID={userWidgetTestId} />,
        ]}
      />
    </DropdownMenuContainer>
  );
};
