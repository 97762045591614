import { FC } from 'react';
import { useSidebar } from '../../pages/supervisors/SidebarState';
import { useMyCambridgeLink } from '../../boot';
import { SupervisorsPageHeader } from '../../components/Layout';

export const SupervisorsHeaderContainer: FC = () => {
  const {
    state: { visible },
  } = useSidebar();

  const link = useMyCambridgeLink();

  return (
    <SupervisorsPageHeader
      visible={visible}
      link={link}
      title="Upcoming Sessions"
      testID="page-title"
      titleProps={{ id: 'upcoming-sessions-title' }}
    />
  );
};
