import { MouseEvent, useMemo } from 'react';
import { Centre, Message } from '../api';
import { RemovalMessageView, ReplacementMessageView } from './views';
import { Option } from '../components/FilterComboBox';
import { Tab } from '../components/DatePicker';
import { hash } from '../sessions/readSessions';
import dayjs from 'dayjs';
import { buildDateQuery, buildQuery } from '../sessions';

export const useRenderMessages = (
  messages: Message[],
  onClick: (event: MouseEvent<HTMLElement>) => void,
  centre: Centre | null,
  active: string | null,
  testID?: string,
): JSX.Element[] =>
  useMemo(
    () =>
      messages.map((message) => {
        const time = calcTime(message.createdAt);

        switch (message.type) {
          case 'REPLACEMENT':
            return (
              <ReplacementMessageView
                active={active === message.id}
                key={message.id}
                item={message}
                onClick={onClick}
                link={useLink(message, centre)}
                testID={testID}
                time={time}
              />
            );

          case 'REMOVAL':
            return (
              <RemovalMessageView
                active={active === message.id}
                key={message.id}
                item={message}
                onClick={onClick}
                testID={testID}
                time={time}
              />
            );
        }
      }),
    [active, centre, messages, onClick, testID],
  );

interface SittingMap {
  [key: string]: string;
}

const useLink = (item: Message, centre: Centre | null) => {
  const { product, sitting, date, specialArrangement } = item;
  const sittingLabels: SittingMap = { AM: 'AM', PM: 'PM', EV: 'EVE' };
  const filterOptions: Option[] = [];
  const dateRange: Tab | null = {
    from: new Date(date),
    to: null,
    id: 'date',
  };

  filterOptions.push({
    group: 'product',
    value: hash(product),
    label: product,
  });

  filterOptions.push({
    group: 'sitting',
    value: sitting,
    label: sittingLabels[sitting],
  });

  filterOptions.push({
    group: 'specialArrangement',
    value: specialArrangement ? 'special_arrangement' : 'not_special_arrangement',
    label: specialArrangement ? 'Yes' : 'No',
  });

  const filterQuery = buildQuery(filterOptions);
  const dateQuery = buildDateQuery(dateRange);

  const params = new URLSearchParams();
  filterQuery && params.set('q', filterQuery);
  dateQuery && params.set('d', dateQuery);

  return centre ? `/centre/${centre.id}?${params}` : undefined;
};

const calcTime = (time: number): string => {
  const now = dayjs().unix();
  const diff = now >= time ? now - time : 0;

  if (diff < 60) return `${Math.floor(diff)}sec`;
  if (diff < 60 * 60) return `${Math.floor(diff / 60)}min`;
  if (diff < 60 * 60 * 24) return `${Math.floor(diff / 60 / 60)}h`;

  return `${Math.floor(diff / 60 / 60 / 24)}d`;
};
