import { FC, useCallback } from 'react';
import { Option } from '../../components/FilterComboBox';
import { useSessionFilter } from '..';
import { SessionsFilterComboBoxView } from './SessionsFilterComboBoxView';

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const filterOptions = (options: Option[], value: string): Option[] => {
  const regex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  return options.filter(
    (option) => regex.exec(option.label) || (!value.includes(',') && option.acronym && regex.exec(option.acronym)),
  );
};

export const SessionsFilterComboBox: FC = () => {
  const {
    comboBox: { selected, setSelected, options, groups },
  } = useSessionFilter();

  const onSelectionChange = useCallback(
    (options: Option[]): void => {
      setSelected(options);
    },
    [setSelected],
  );

  const readOptions = useCallback((input: string) => (!input ? options : [...filterOptions(options, input)]), [
    options,
  ]);

  return (
    <SessionsFilterComboBoxView
      id="search-component"
      selected={selected}
      onSelectionChange={onSelectionChange}
      options={readOptions}
      groups={groups}
    />
  );
};
