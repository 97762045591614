import { useEffect } from 'react';
import { Dispatch, StateResult } from './types';

export const useFocusHandler = (state: StateResult, dispatch: Dispatch): void => {
  const { input } = state.current.refs;

  useEffect(() => {
    const inputElement = input.current;
    if (inputElement) {
      const focusin = () => {
        dispatch(['FOCUS_IN']);
      };

      inputElement.addEventListener('focusin', focusin);
      return () => inputElement.removeEventListener('focusin', focusin);
    }
  }, [dispatch, input]);

  useEffect(() => {
    const inputElement = input.current;
    if (inputElement) {
      const focusout = () => {
        dispatch(['FOCUS_OUTSIDE']);
      };

      inputElement.addEventListener('focusout', focusout);
      return () => inputElement.removeEventListener('focusout', focusout);
    }
  }, [dispatch, input]);
};
