import clsx from 'clsx';
import { FC, useCallback } from 'react';
import {
  filterContainer,
  inputContainer,
  datePickerContainer,
  inputCentreContainer,
} from '../../components/ReportsPageLayout/views.module.scss';
import { Tab, TabInformation, Range } from '../../components/DatePicker';
import { dataTestID, WithIProps, childTestID } from '../../util/test-id';
import { TabList, ReportsTabButton } from '../../components/Tabs';
import {
  LabelView,
  ReportsArticleFilter,
  ReportsDatePickerView,
  ReportsCentreFilter,
} from '../../components/ReportsPageLayout/views';
import { useReports } from '../../reports';
import { Article } from '../../components/ReportsFilterComboBox';
import { Option } from '../../components/FilterComboBox';

interface ReportsArticleFilterContainerProps extends WithIProps<'div'> {
  range: Range;
  yearFrom?: Date;
}

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const filterArticleOptions = (articles: Article[], value: string): Article[] => {
  const rex = new RegExp([value].map(escapeRegex).join('.*?'), 'i');
  return articles.filter((article) => rex.exec(`${article.id}`));
};

const filterCentresOptions = (options: Option[], value: string): Option[] => {
  const regex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  return options.filter((option) => regex.exec(option.label));
};

export const ReportsArticleFilterContainer: FC<ReportsArticleFilterContainerProps> = ({
  testID,
  className,
  range,
  yearFrom,
  ...rest
}) => {
  const {
    articleComboBox: { selectedArticle, articleOptions, focused },
    centresComboBox: { selectedCentres, centreOptions, groups, setSelectedCentre },
    datePicker: { onRangeSelect, selectedRange, tabs },
    tabId,
  } = useReports();

  const onDatepickerApply = useCallback(
    (selectedRange: Tab | null): void => {
      onRangeSelect(selectedRange);
    },
    [onRangeSelect],
  );

  const onSelectionChange = useCallback(
    (options: Option[]): void => {
      setSelectedCentre(options);
    },
    [setSelectedCentre],
  );

  const readArticles = useCallback(
    (input: string) => (!input ? articleOptions : [...filterArticleOptions(articleOptions, input)]),
    [articleOptions],
  );

  const readCentres = useCallback(
    (input: string) => (!input ? centreOptions : [...filterCentresOptions(centreOptions, input)]),
    [centreOptions],
  );

  return (
    <div className={clsx(filterContainer, className)} {...dataTestID(testID)} {...rest}>
      {tabId === 'ARTICLE' && (
        <div className={inputContainer}>
          <LabelView>Article No.</LabelView>
          <ReportsArticleFilter
            selected={selectedArticle}
            options={readArticles}
            focused={focused}
            testID="filter-view"
          />
        </div>
      )}
      {tabId === 'CENTRE' && (
        <div className={inputCentreContainer}>
          <LabelView>Centre number</LabelView>
          <ReportsCentreFilter
            selected={selectedCentres}
            options={readCentres}
            groups={groups}
            onSelectionChange={onSelectionChange}
            testID="filter-centres-view"
          />
        </div>
      )}
      <div className={datePickerContainer}>
        <LabelView>Date range</LabelView>
        <ReportsDatePickerView
          id="reports-date-picker-component"
          selected={selectedRange}
          onApply={onDatepickerApply}
          tabs={tabs}
          testID="date-picker"
          range={range}
          noFutureDates={true}
          maxSpanOfDaysBetweenDates={30}
          yearFrom={yearFrom}
        />
      </div>
    </div>
  );
};

interface TabsProps extends WithIProps<'div'> {
  id: string;
  tabs: TabInformation[];
}

export const TabsContainer: FC<TabsProps> = ({ id, tabs, testID }) => {
  const { setTabIdValue, tabId } = useReports();

  const onClick = useCallback(() => {
    setTabIdValue(tabId === 'ARTICLE' ? 'CENTRE' : 'ARTICLE');
  }, [setTabIdValue, tabId]);

  return (
    <TabList aria-label="reports tabs" role="button" testID={childTestID(testID, 'reports-tabs')}>
      {tabs.map((tab) => (
        <ReportsTabButton key={tab.id} group={id} tabId={tab.id} onClick={onClick} active={tabId === tab.id}>
          {tab.name}
        </ReportsTabButton>
      ))}
    </TabList>
  );
};
