import { KeyboardEvent, useMemo } from 'react';
import { Action, Dispatch, StateResult } from './types';

type OnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => void;

export const useKeyboardHandler = (state: StateResult, dispatch: Dispatch): OnKeyDown => {
  return useMemo(
    (): OnKeyDown => (event) => {
      const { key } = event;
      const { current } = state;
      const { open } = current;

      const trigger = (action: Action) => {
        event.preventDefault();
        dispatch(action);
      };

      if (key === 'Escape') {
        if (open) trigger(['HIDE_DIALOG']);
      }

      if (key === 'Enter') {
        if (!open) trigger(['SHOW_DIALOG']);
      }

      if (key === ' ') {
        if (!open) trigger(['SHOW_DIALOG']);
      }
    },
    [dispatch, state],
  );
};
