import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useQuery, useQueryURL } from '../../Routes';
import { WithIProps, dataTestID, childTestID } from '../../util/test-id';
import { LinkButton, LinkIconButton, DisabledLinkIconButton, Button } from '../Button';
import { LeftArrow, RightArrow } from '../Icon';
import { active, item, list, root, ellipsis } from './Pagination.module.scss';
import { TextMBold } from '../../theme/typography.module.scss';

export interface PaginationProps extends WithIProps<'nav'> {
  current: number;
  total: number;
  'aria-label': string;
  labels?: {
    next?: string;
    previous?: string;
  };
  to: (page: number) => string;
}

const numberToList = (total: number): number[] => [...Array(total)].map((_, i) => i + 1);

const pageList = (current: number, total: number, length = 2): (string | number)[] => {
  const range = [];
  const rangeWithDots = [];
  let l;

  const visible = 3 + length * 2;

  if (total <= visible) return numberToList(total);

  if (current <= visible - length) {
    for (let i = 1; i < visible; i++) {
      rangeWithDots.push(i);
    }
    rangeWithDots.push(String.fromCharCode(0x2026));

    rangeWithDots.push(total);
    return rangeWithDots;
  }

  if (current > total - visible + length) {
    rangeWithDots.push(1);

    rangeWithDots.push(String.fromCharCode(0x2026));

    for (let i = total - visible + length; i <= total; i++) {
      rangeWithDots.push(i);
    }

    return rangeWithDots;
  }

  range.push(1);

  for (let i = current - length; i <= current + length; i++) {
    range.push(i);
  }
  range.push(total);

  for (const i of range) {
    if (l && i - l !== 1) {
      rangeWithDots.push(String.fromCharCode(0x2026));
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

export const Pagination: FC<PaginationProps> = ({
  total,
  current,
  className,
  to,
  labels = {},
  children,
  testID,
  ...rest
}) => {
  if (total < 2) return null;

  const { previous = 'Previous', next = 'Next' } = labels;

  const pages = pageList(current, total).map((page, i) => {
    if (isFinite(+page)) {
      if (current === page) {
        return (
          <li key={page} className={item}>
            <Button
              size="small"
              variant="text"
              className={active}
              aria-current="page"
              testID={childTestID(testID, 'page-' + page)}
            >
              {page}
            </Button>
          </li>
        );
      }
      return (
        <li key={page} className={item}>
          <LinkButton to={to(+page)} size="small" variant="text" testID={childTestID(testID, 'page-' + page)}>
            {page}
          </LinkButton>
        </li>
      );
    }

    return (
      <li key={String(page).concat(String(i))} className={item}>
        <span role="img" aria-label="ellipses" className={clsx(TextMBold, ellipsis)}>
          {page}
        </span>
      </li>
    );
  });

  return (
    <nav className={clsx(root, className)} {...dataTestID(testID)} {...rest}>
      <ul className={list}>
        {current <= 1 && (
          <li className={item}>
            <DisabledLinkIconButton
              aria-hidden="true"
              size="small"
              variant="text"
              testID={childTestID(testID, 'previous')}
            >
              <LeftArrow />
            </DisabledLinkIconButton>
          </li>
        )}

        {current > 1 && (
          <li className={item}>
            <LinkIconButton
              aria-label={previous}
              to={to(current - 1)}
              size="small"
              variant="text"
              testID={childTestID(testID, 'previous')}
            >
              <LeftArrow />
            </LinkIconButton>
          </li>
        )}
        {pages}
        {current < total && (
          <li className={item}>
            <LinkIconButton
              aria-label={next}
              to={to(current + 1)}
              size="small"
              variant="text"
              testID={childTestID(testID, 'next')}
            >
              <RightArrow />
            </LinkIconButton>
          </li>
        )}
        {current >= total && (
          <li className={item}>
            <DisabledLinkIconButton aria-hidden="true" size="small" variant="text" testID={childTestID(testID, 'next')}>
              <RightArrow />
            </DisabledLinkIconButton>
          </li>
        )}
      </ul>
      {children}
    </nav>
  );
};

export const useCurrentPage = (): number => +useQuery().page || 1;

export const ConnectedPagination: FC<Omit<PaginationProps, 'to' | 'current'>> = ({ ...rest }) => {
  const template = useQueryURL({ page: '--PAGE--' });
  const first = useQueryURL({ page: null });
  const to = useCallback((page: number) => (page < 2 ? first : template.replace('--PAGE--', String(page))), [
    first,
    template,
  ]);
  return <Pagination to={to} current={useCurrentPage()} {...rest} />;
};
