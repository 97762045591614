import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { HeadingMRegular, TextXSRegular } from '../../theme/typography.module.scss';
import { dataTestID, WithIProps } from '../../util/test-id';
import { root, header, content, centeredTitle } from './Layout.module.scss';

export const Layout: FC<WithIProps<'main'>> = ({ testID, className, ...rest }) => (
  <main className={clsx(root, className)} {...dataTestID(testID)} {...rest} />
);

export interface HeaderProps extends Omit<WithIProps<'div'>, 'title'> {
  title: NonNullable<ReactNode>;
  titleProps?: WithIProps<'h2'>;
  centered?: boolean;
}

export const PageHeader: FC<HeaderProps> = ({
  title,
  titleProps,
  centered = false,
  testID,
  className,
  children,
  ...rest
}) => (
  <div className={clsx(header, { [centeredTitle]: centered }, className)} data-qa-id={testID} {...rest}>
    <h2 className={HeadingMRegular} {...dataTestID(testID, 'title')} {...titleProps}>
      {title}
    </h2>
    {children}
  </div>
);

interface SupervisorsHeaderProps extends HeaderProps {
  visible: boolean;
  link: string;
}

export const SupervisorsPageHeader: FC<SupervisorsHeaderProps> = ({
  title,
  visible,
  link,
  titleProps,
  centered = false,
  testID,
  className,
  children,
  ...rest
}) => (
  <div className={clsx(header, { [centeredTitle]: centered }, className)} data-qa-id={testID} {...rest}>
    <h2 className={HeadingMRegular} {...dataTestID(testID, 'title')} {...titleProps}>
      {title}
    </h2>
    {visible && (
      <div className={TextXSRegular}>
        If you cannot see the user you wish to assign please click&nbsp;
        <a href={link} target="_blank" rel="noopener noreferrer">
          here
        </a>
        &nbsp;to manage users
      </div>
    )}
    {children}
  </div>
);

export const Content: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => (
  <div className={clsx(content, className)} {...dataTestID(testID, 'content')} {...rest} />
);
