import { cloneElement, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { childTestID, WithIProps } from '../../util/test-id';
import { DropdownMenuContainer } from '../DropDown';
import { MessagesButton, MessagesDropDownList, MessagesListTitle, MessagesWrapper, Separator } from './views';

export interface MessagesDropdownProps extends WithIProps<'div'> {
  count: number;
  showCount: boolean;
  list: JSX.Element[];
  loading: boolean;
  onDropdownOpen: () => void;
}

export const MessagesDropdown: FC<MessagesDropdownProps> = ({
  count,
  showCount,
  list,
  loading,
  onDropdownOpen,
  testID,
  ...rest
}) => {
  const buttonId = `messages-list-trigger`;
  const menuId = `messages-list`;
  const messagesTitleId = 'messages-list-title';

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClick = useCallback(() => setOpen((value) => !value), []);

  useEffect(() => {
    if (open) {
      onDropdownOpen();
    }
  }, [onDropdownOpen, open]);

  const listWithAttributes = useMemo(
    () =>
      list.map((message) => {
        return (
          <Fragment key={message.key}>
            {cloneElement(message, {
              'data-item': 'menuitem',
              tabIndex: -1,
              role: 'listitem',
            })}
          </Fragment>
        );
      }),
    [list],
  );

  return (
    <DropdownMenuContainer
      testID={childTestID(testID, `messages-dropdown`)}
      open={open}
      downOnly={true}
      onClose={onClose}
      onOpen={onOpen}
      {...rest}
    >
      <MessagesButton
        testID={testID}
        id={buttonId}
        onClick={onClick}
        active={open}
        aria-controls={menuId}
        aria-expanded={open}
        aria-haspopup="true"
        aria-label="open messages dropdown"
        count={count}
        showCount={!loading && showCount}
      />

      <MessagesDropDownList
        visible={open}
        id={menuId}
        aria-labelledby={buttonId}
        testID={childTestID(testID, menuId)}
        role="list"
        items={[
          <MessagesListTitle id={messagesTitleId} testID={testID} key="title" role="none" onClose={onClose} />,
          <Separator key="separator" role="separator" testID={testID} />,
          <MessagesWrapper key="list" list={listWithAttributes} loading={loading} role="none" testID={testID} />,
        ]}
      />
    </DropdownMenuContainer>
  );
};
