import { Article, Session, SessionsResponse } from '../api';

interface SittingValues {
  [key: string]: number;
}

export const SITTING_VALUES: SittingValues = { AM: 0, PM: 1, EV: 2 };

type SessCompare = (a: Session, b: Session) => number;
type Order = [SessCompare, 1 | -1];

export const isValidDownload = ({ download }: Session): boolean => !download || download.length > 0;

const compareKAD: SessCompare = (a, b) => +a.date - +b.date;
const compareAvailable: SessCompare = (a, b) => +a.available.from - +b.available.from;
const compareSitting: SessCompare = (a, b) => SITTING_VALUES[a.sitting] - SITTING_VALUES[b.sitting];
const compareDownload: SessCompare = (a, b) => +isValidDownload(a) - +isValidDownload(b);
const compareProduct: SessCompare = (a, b) => a.article.product.localeCompare(b.article.product);
const compareSA: SessCompare = (a, b) => +a.article.specialArrangement - +b.article.specialArrangement;

const compareSessions = (order: Order[]): SessCompare => (a, b) => {
  for (const [func, direction] of order) {
    const res = func(a, b);
    if (res) return direction * res;
  }
  return 0;
};

type SortKey = keyof typeof sort;

const sort = {
  default: compareSessions([
    [compareDownload, 1],
    [compareKAD, 1],
    [compareSitting, 1],
    [compareProduct, 1],
    [compareSA, 1],
    [compareAvailable, 1],
  ]),
};

type SortMap = Record<SortKey, Session[]>;

const createId = (item: Session<'raw'>): string => [item.article, item.date, item.sitting].join('-');

export const hash = (input: string, size = 3): string => {
  let list = input.split('').map((e) => e.charCodeAt(0) % 256);
  while (list.length > size) {
    const r = [];
    for (let i = 1; i < list.length; i++) {
      r[i - 1] = (list[i - 1] + list[i]) % 256;
    }
    list = r;
  }
  return btoa(String.fromCharCode(...list))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

const toSessions = ({ sessions, articles }: SessionsResponse): Session[] => {
  const productIdMap = articles.reduce<Record<string, string>>((acc, article) => {
    acc[article.product] = acc[article.product] || hash(article.product);
    return acc;
  }, {});

  const articleMap = articles
    .map((item) => ({ ...item, pid: productIdMap[item.product] }))
    .reduce<Record<string, Article>>((acc, item) => ((acc[item.id] = item), acc), {});

  return sessions
    .filter(({ article }) => !!articleMap[article])
    .map(
      (session): Session => ({
        ...session,
        id: createId(session),
        date: new Date(session.date),
        available: {
          to: new Date(session.available.to * 1000),
          from: new Date(session.available.from * 1000),
        },
        article: articleMap[session.article],
      }),
    )
    .map((s) => (s.download ? { ...s, download: s.download.filter((d) => d.format.toUpperCase() !== 'FLAC') } : s));
};

const toSortMap = (sessions: Session[]): SortMap =>
  (Object.keys(sort) as SortKey[]).reduce<SortMap>((acc, key) => {
    acc[key] = sessions.slice().sort(sort[key]);
    return acc;
  }, {} as SortMap);

export interface SessionsData {
  sorted: SortMap;
  items: Record<string, Session>;
}

export const readSessions = (response: SessionsResponse): SessionsData => {
  const sessions = toSessions(response);
  const sorted = toSortMap(sessions);
  const items = sessions.reduce<Record<string, Session>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  return { sorted, items };
};
