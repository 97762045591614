import { FC, useCallback, MouseEvent, ReactNode } from 'react';
import { Notification, NotificationContent, NotificationTitle } from '../components/Notification';
import { HourGlass } from '../components/Icon';
import { NotificationType, useNotification } from './NotificationProvider';
import { ErrorIcon, ProgressBar } from './views';
import { hourGlass } from './views.module.scss';
import { childTestID } from '../util/test-id';

const icons: Record<NotificationType, ReactNode> = {
  ERROR: <ErrorIcon />,
  WAITING: <HourGlass className={hourGlass} />,
  SUCCESS: null,
  WARNING: null,
};

export const Notifications: FC = () => {
  const { state, dispatch } = useNotification();

  const onClose = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const { notification } = event.currentTarget.dataset;

      if (notification && (isFinite(+notification) || typeof notification === 'string'))
        dispatch(['REMOVE', isNaN(+notification) ? notification : +notification]);
    },
    [dispatch],
  );

  if (!state.length) return null;

  const { id, content, type } = state[state.length - 1];

  const testID = childTestID('notification', id.toString());

  return (
    <Notification testID={testID} key={id}>
      <NotificationTitle testID={testID} onClose={onClose} notificationId={id} icon={icons[type]}>
        {content.title}
      </NotificationTitle>
      <NotificationContent testID={testID}>
        {content.body}
        {type === 'WAITING' && <ProgressBar testID={testID} />}
      </NotificationContent>
    </Notification>
  );
};
