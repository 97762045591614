import { useEffect } from 'react';
import { Dispatch, StateResult } from './types';
import { useReports } from '../../reports';

export const useFocusHandler = (state: StateResult, dispatch: Dispatch): void => {
  const { container } = state.current.refs;

  const {
    articleComboBox: { setFocusedContainer },
  } = useReports();

  useEffect(() => {
    const containerElement = container.current;
    if (containerElement) {
      const focusin = () => {
        dispatch(['FOCUS_IN']);
        setFocusedContainer(true);
        containerElement.focus();
      };

      containerElement.addEventListener('focusin', focusin);
      return () => containerElement.removeEventListener('focusin', focusin);
    }
  }, [container, dispatch, setFocusedContainer]);

  useEffect(() => {
    const containerElement = container.current;
    if (containerElement) {
      const focusout = (event: FocusEvent) => {
        const related = event.relatedTarget as HTMLElement | null;
        if (!related || !containerElement.contains(related)) {
          dispatch(['FOCUS_OUTSIDE']);
          setFocusedContainer(false);
        }
      };

      containerElement.addEventListener('focusout', focusout);
      return () => containerElement.removeEventListener('focusout', focusout);
    }
  }, [container, dispatch, setFocusedContainer]);
};
