import clsx from 'clsx';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { root } from './EmptyState.module.scss';
import { HeadingMRegular } from '../../theme/typography.module.scss';

export interface EmptyStateProps extends HTMLAttributes<HTMLDivElement> {
  testID?: string;
  message: NonNullable<ReactNode>;
}

export const EmptyState: FC<EmptyStateProps> = ({ className, children, message, testID, ...rest }) => (
  <div className={clsx(root, className)} data-qa-id={testID} {...rest}>
    <h1 className={HeadingMRegular} data-qa-id={testID ? `${testID}-message` : undefined}>
      {message}
    </h1>
    {children}
  </div>
);
