import { FC, Fragment, MouseEvent, useCallback, useMemo, useState } from 'react';
import { Download } from '../api';
import { Button, SpanButton } from '../components/Button';
import { DropdownMenuContainer, DropdownMenuList } from '../components/DropDown';
import { ArrowDownward } from '../components/Icon';
import { ConnectedPagination } from '../components/Pagination';
import { useSignature } from '../singnature';
import { useSessionList, useSessionItem } from '../sessions';
import { useNotification } from '../notifications';
import { NotificationWaitingMessage } from '../notifications/views';
import { Session } from '../api/models';
import {
  AvailableFromMessage,
  GetHelpMessage,
  SessionRow,
  SessionsTable,
  PreparingFileMessage,
  ReplacementFileWarning,
  NewFileWarning,
} from './views';
import { useCentres } from '../centres';
import { useBootState } from '../boot';
import dayjs from 'dayjs';

interface FileInfo {
  product: string;
  specialArrangement: boolean;
  date: Date;
  sitting: string;
  articleNo: string;
}

const DownloadAction: FC<{ id: string }> = ({ id }) => {
  const item = useSessionItem(id);
  if (!item) return null;

  const {
    download,
    article: { product, specialArrangement },
    date,
    sitting,
    available: { from },
    isNew,
  } = item;

  const fileInfo: FileInfo = {
    product,
    specialArrangement,
    date,
    sitting,
    articleNo: id,
  };

  const isReplacementFile = useMemo(() => download && download.length && download[0].isReplacement, [download]);
  const isNewFile = useMemo(() => download && download.length && !download[0].isReplacement && isNew, [
    download,
    isNew,
  ]);

  const signing = !!download && useDownloadPending(download);

  if (!download) return <AvailableFromMessage date={from} />;
  if (!download.length) return <GetHelpMessage testID={`session-${id}`} />;
  if (signing) return <PreparingFileMessage />;

  return (
    <Fragment>
      {isReplacementFile && <ReplacementFileWarning testID={`session-${id}`} />}
      {isNewFile && <NewFileWarning testID={`session-${id}`} />}
      <DownloadDropdown id={id} list={download} fileInfo={fileInfo} />
    </Fragment>
  );
};

const useDownloadPending = (list: Download[]): boolean => {
  const {
    state: { requests },
  } = useSignature();
  return list.some((item) => !!requests[item.filename]);
};

export const DownloadDropdown: FC<{ id: string; list: Download[]; fileInfo: FileInfo }> = ({ id, list, fileInfo }) => {
  const buttonId = `${id}-trigger`;
  const menuId = `${id}-menu`;

  const session = useSessionItem(id);
  const boot = useBootState();
  const assignedSessions = useMemo(() => (!boot.loading && boot.user?.globalListening?.sessions) || [], [boot]);
  const { selectedCentre } = useCentres();
  const activeDownload = useMemo(
    () =>
      !!selectedCentre &&
      selectedCentre.roles.some(
        (r) =>
          r === 'EXAMS_OFFICER' ||
          r === 'DELEGATED_ADMIN' ||
          (r === 'SUPERVISOR' &&
            session &&
            assignedSessions.some(
              (s) =>
                s.legacyCentreNumber === selectedCentre.id &&
                s.articleNumber === session.article.id &&
                s.keyAssessmentDate === dayjs(session.date).format('YYYY-MM-DD') &&
                s.sitting === session.sitting,
            )),
      ),
    [assignedSessions, selectedCentre, session],
  );

  const { download } = useSignature();
  const { dispatch: dispatchNotification, messages, assignMessage } = useNotification();
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClick = useCallback(() => setOpen((value) => !value), []);

  const onLinkClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setOpen(false);
      const { filename, format } = event.currentTarget.dataset;
      if (filename) {
        download(filename);

        assignMessage(
          <NotificationWaitingMessage
            product={fileInfo.product}
            sa={fileInfo.specialArrangement}
            date={fileInfo.date}
            sitting={fileInfo.sitting}
            format={format}
          />,
          'FILE_BEING_PREPARED',
        );

        dispatchNotification([
          'ADD',
          {
            articleNo: fileInfo.articleNo,
            title: `Preparing file for ${fileInfo.product}`,
            body: messages.FILE_BEING_PREPARED,
          },
          'WAITING',
        ]);
      }
    },
    [download, dispatchNotification, assignMessage, messages, fileInfo],
  );

  return (
    <DropdownMenuContainer testID={`session-${id}-download-dropdown`} open={open} onClose={onClose} onOpen={onOpen}>
      <Button
        disabled={!activeDownload}
        id={buttonId}
        variant="text"
        size="small"
        startIcon={<ArrowDownward />}
        aria-haspopup="true"
        aria-controls={menuId}
        onClick={onClick}
        active={open}
        aria-expanded={open}
      >
        Download
      </Button>
      <DropdownMenuList
        visible={open}
        id={menuId}
        aria-labelledby={buttonId}
        items={list.map(({ format, filename }) => (
          <SpanButton
            size="small"
            variant="text"
            key={format}
            data-filename={filename}
            data-format={format}
            onClick={onLinkClick}
          >
            .{format.toLocaleLowerCase()}
          </SpanButton>
        ))}
      />
    </DropdownMenuContainer>
  );
};

const CentreSessionRow: FC<{ item: Session }> = ({ item }) => (
  <SessionRow
    testID={`session-${item.id}`}
    product={item.article.product}
    date={item.date}
    sitting={item.sitting}
    specialArrangements={item.article.specialArrangement}
    actions={<DownloadAction id={item.id} />}
  />
);

export const CentreSessionsTable: FC = () => {
  const sessionList = useSessionList();
  const { totalPages, list } = sessionList;

  const itemArr = list?.map((id) => useSessionItem(id));
  const rows = (itemArr as Session[])?.map((s: Session) => s && <CentreSessionRow item={s} key={s.id} />);

  return (
    <SessionsTable
      rows={rows}
      testID="sessions-list"
      aria-describedby="upcoming-sessions-title"
      pagination={totalPages > 1 && <ConnectedPagination aria-label="Session list navigation" total={totalPages} />}
    />
  );
};
