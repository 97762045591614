import { useMemo } from 'react';
import { Tab, TabInformation } from './types';

interface BarInfoParams {
  tabs: TabInformation[];
  open: boolean;
  selected: Tab | null;
  activeTabPanel: string | null;
}

interface BarInfo {
  name?: string;
  from: Date | null;
  to: Date | null;
  multiple?: boolean;
}

export const useBarInfo = ({ tabs, open, selected, activeTabPanel }: BarInfoParams): BarInfo => {
  return useMemo((): BarInfo => {
    const multiple = tabs.length !== 1;

    if (open) {
      const tab = tabs.find((tab) => tab.id === activeTabPanel);
      if (!tab) return { from: null, to: null, multiple };

      const name = tab.name;
      if (!selected || selected.id !== activeTabPanel) {
        return { name, from: null, to: null, multiple };
      }

      return { name, from: selected.from, to: selected.to, multiple };
    }

    if (selected) {
      const tab = tabs.find((tab) => tab.id === selected.id);
      if (!tab) return { from: null, to: null, multiple };
      return { name: tab.name, from: selected.from, to: selected.to, multiple };
    }

    return { from: null, to: null, multiple };
  }, [activeTabPanel, open, selected, tabs]);
};
