import { FC } from 'react';
import { Message } from '../api';
import { DateFormat, DateTime } from '../components/DateTime';
import { Circle, Minus } from '../components/Icon';
import { MessageContainer, MessageContent, MessageTitle, MessageLink } from '../components/Message';
import { childTestID, WithIProps } from '../util/test-id';
import styles from './styles.module.scss';

interface MessageViewProps extends WithIProps<'div'> {
  item: Message;
  active: boolean;
  time: string;
  link?: string;
}

export const RemovalMessageView: FC<MessageViewProps> = ({ time, item, active, testID, ...rest }) => {
  const { product, date, sitting, id, hasRead } = item;
  return (
    <MessageContainer
      id={id}
      active={active}
      read={hasRead}
      icon={hasRead ? <Minus /> : <Circle className={styles.errorCircle} />}
      testID={testID}
      {...rest}
    >
      <MessageTitle time={time} testID={childTestID(testID, `message-${id}`)}>
        {product} audio file for session <DateTime value={new Date(date)} format={DateFormat.DATE} /> {sitting} was
        deleted
      </MessageTitle>
      <MessageContent active={active} testID={childTestID(testID, `message-${id}`)}>
        The audio file for {product} on <DateTime value={new Date(date)} format={DateFormat.DATE} /> {sitting} is due to
        be replaced, please delete any copies of the file you previously downloaded for this session, and download the
        replacement file once it becomes available.
      </MessageContent>
    </MessageContainer>
  );
};

export const ReplacementMessageView: FC<MessageViewProps> = ({ link, time, item, active, testID, ...rest }) => {
  const { product, date, sitting, id, specialArrangement, hasRead } = item;
  return (
    <MessageContainer
      id={id}
      active={active}
      read={hasRead}
      icon={hasRead ? <Minus /> : <Circle className={styles.errorCircle} />}
      testID={testID}
      {...rest}
    >
      <MessageTitle time={time} testID={childTestID(testID, `message-${id}`)}>
        {product} audio file for session <DateTime value={new Date(date)} format={DateFormat.DATE} /> {sitting} was
        replaced
      </MessageTitle>
      <MessageContent active={active} testID={childTestID(testID, `message-${id}`)}>
        The audio file for {product} on <DateTime value={new Date(date)} format={DateFormat.DATE} /> {sitting} has been
        replaced, please delete any copies of the file you previously downloaded for this session, and download the
        replacement file.
        {link && (
          <MessageLink
            product={product}
            sitting={sitting}
            date={date ? new Date(date) : new Date()}
            specialArr={specialArrangement}
            link={link}
            testID={childTestID(testID, `message-link-${id}`)}
          >
            Go to file
          </MessageLink>
        )}
      </MessageContent>
    </MessageContainer>
  );
};
