import { createContext, createElement, Dispatch, FC, useContext, useMemo, useReducer, useEffect } from 'react';
import { NotificationWaitingMessage } from './views';
import { clearSessionStorageNotifications } from '../boot';

type Messages =
  | 'FILE_NOT_FOUND'
  | 'WATERMARKING_FAILED'
  | 'NOT_IN_DOWNLOAD_WINDOW'
  | 'INCORRECT_FILENAME'
  | 'NO_DOWNLOAD_ACTIVITY'
  | 'ACCESS_DENIED'
  | 'FILE_BEING_PREPARED'
  | 'FAILED_SAVE_CHANGES'
  | 'FAILED_TO_REMOVE_SUPERVISORS'
  | 'FAILED_TO_ASSIGN_SUPERVISORS'
  | 'FAILED_UPDATE_USER_INFO'
  | 'FAILED_FETCH_MESSAGES';

export const messages: Record<Messages, string | JSX.Element> = {
  FILE_NOT_FOUND:
    'File may have been removed. Please refresh your browser to view latest information. If error persists please contact support.',
  WATERMARKING_FAILED:
    'Try refreshing your browser and downloading a different file format. If error persists please contact support.',
  NOT_IN_DOWNLOAD_WINDOW: "Cant't download file at this moment. Try again later",
  INCORRECT_FILENAME: 'Incorrect filename.',
  ACCESS_DENIED: 'Not authorized to download files from selected centre.',
  NO_DOWNLOAD_ACTIVITY:
    'There was no download activity against this article number in the date range selected, please select another date range.',
  FILE_BEING_PREPARED: '',
  FAILED_UPDATE_USER_INFO: 'Could not save user consent.',
  FAILED_TO_REMOVE_SUPERVISORS: 'Could not assign supervisors to sessions.',
  FAILED_TO_ASSIGN_SUPERVISORS: 'Could not remove supervisors from sessions.',
  FAILED_SAVE_CHANGES: 'Could not save changes to supervisors.',
  FAILED_FETCH_MESSAGES: 'Could not get messages.',
};

export const assignMessage = (text: string | JSX.Element, messageType: Messages): void => {
  messages[messageType] = text;
};

export type NotificationContextProps = {
  state: State;
  messages: Record<Messages, string | JSX.Element>;
  assignMessage: (text: string | JSX.Element, messageType: Messages) => void;
  dispatch: Dispatch<Action>;
};

const NotificationContext = createContext<NotificationContextProps>({
  state: [],
  messages,
  assignMessage: () => {
    throw new Error('Missing `NotificationProvider`');
  },
  dispatch: () => {
    throw new Error('Missing `NotificationProvider`');
  },
});

export const useNotification = (): NotificationContextProps => useContext(NotificationContext);

export type NotificationType = 'SUCCESS' | 'WARNING' | 'ERROR' | 'WAITING';

export type NotificationContent = {
  title: string;
  body: string | JSX.Element;
  articleNo?: string;
  type?: NotificationType;
};

export type Notification = {
  id: number | string;
  content: NotificationContent;
  type: NotificationType;
};

type State = Notification[];

type Action = ['ADD', NotificationContent, NotificationType] | ['REMOVE', number | string] | ['REMOVE_ALL'];

const initialState: Notification[] = [];

const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'ADD': {
      const id = +new Date();

      if (
        action[1].articleNo &&
        !Object.keys(sessionStorage).some((key) => key.includes(String(action[1].articleNo))) &&
        action[2] === 'WAITING'
      ) {
        const content = {
          ...action[1],
          id: action[1].articleNo,
          type: 'WAITING',
        };

        sessionStorage.setItem(`notification-${action[1].articleNo}`, JSON.stringify(content));
      }

      let idToSet;

      if (action[1].articleNo) {
        idToSet = `notification-${action[1].articleNo}`;
      } else {
        idToSet = id;
      }

      return [
        ...state,
        {
          id: idToSet,
          content: action[1],
          type: action[2],
        },
      ];
    }
    case 'REMOVE': {
      const existingInStorage = Object.keys(sessionStorage).find((key) => key.includes(String(action[1])));
      if (existingInStorage) {
        sessionStorage.removeItem(existingInStorage);
      }
      return state.filter((n) => n.id !== action[1]);
    }
    case 'REMOVE_ALL':
      for (const key of Object.keys(sessionStorage)) {
        if (key.includes('notification-')) {
          sessionStorage.removeItem(key);
        }
      }
      return initialState;
  }
};

export const NotificationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  window.onbeforeunload = () => {
    clearSessionStorageNotifications();
  };

  useEffect(() => {
    for (const [key, value] of Object.entries(sessionStorage)) {
      if (key.includes('notification')) {
        const notificationData = JSON.parse(value);
        if (state.length && state.some((info) => notificationData.articleNo.includes(info.id))) return;

        messages['FILE_BEING_PREPARED'] =
          notificationData.type === 'WAITING' ? (
            <NotificationWaitingMessage
              product={notificationData.body?.props?.product}
              sa={notificationData.body?.props?.sa}
              date={notificationData.body?.props?.date}
              sitting={notificationData.body?.props?.sitting}
              format={notificationData.body?.props?.format}
            />
          ) : (
            notificationData.body
          );

        dispatch([
          'ADD',
          { articleNo: notificationData.articleNo, title: notificationData.title, body: messages.FILE_BEING_PREPARED },
          'WAITING',
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo<NotificationContextProps>(() => ({ state, messages, assignMessage, dispatch }), [state]);

  return createElement(NotificationContext.Provider, { value }, children);
};
