import { createContext, createElement, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { CookieConsentBar } from './views';
import { useUserEmailHmac } from '../boot';

interface Context {
  consent: boolean;
  consentCookieName: string | null;
  onConsent: () => void;
}

const Context = createContext<Context>({
  consent: false,
  consentCookieName: null,
  onConsent: () => {
    throw new Error('Missing CookiesConsentState');
  },
});

export const useConsent = (): Context => useContext(Context);

const Bar: FC = () => {
  const { consent, consentCookieName, onConsent } = useConsent();

  if (consentCookieName && !consent)
    return (
      <CookieConsentBar
        text="We use cookies to improve your experience of our website. By clicking on any link you are giving consent for us to set cookies. 
  You can change the cookie settings on your browser at any time."
        link="https://www.cambridgeassessment.org.uk/footer/use-of-cookies/"
        onConsent={onConsent}
        testID="consent-bar"
      />
    );

  return null;
};

export const CookiesConsentState: FC = ({ children }) => {
  const [consent, setConsent] = useState<boolean>(false);
  const emailHmac = useUserEmailHmac();

  const consentCookieName = useMemo(() => (emailHmac && `CONSENT-${emailHmac}`) || null, [emailHmac]);

  useEffect(() => {
    if (consentCookieName) {
      setConsent(Cookies.get(consentCookieName) === 'true');
    }
  }, [consentCookieName]);

  const onConsent = useCallback(() => {
    if (consentCookieName) {
      Cookies.set(consentCookieName, 'true', { path: '/', expires: 365 });
      setConsent(true);
    }
  }, [consentCookieName]);

  const consentBar = useMemo(() => createElement(Bar), []);

  return createElement(Context.Provider, { value: { consent, consentCookieName, onConsent } }, children, consentBar);
};
