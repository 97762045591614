import { createContext, FC, useContext, useMemo } from 'react';
import { useCurrentPage } from '../components/Pagination';
import { useProvider } from '../util/useProvider';
import { useSessionFilter } from './SessionFilterProvider';
import { useSessionSort } from './SessionSortProvider';
import { getSortedSessions } from './sessions-sort-util';
import { useSessions } from './SessionsProvider';

interface ListContextProps {
  list: string[] | null;
  totalPages: number;
}

const ListContext = createContext<ListContextProps>({ totalPages: 0, list: null });

export const useSessionList = (): ListContextProps => useContext(ListContext);

export const SessionListProvider: FC = ({ children }) => {
  const currentPage = useCurrentPage();
  const pageSize = 25;
  const sessions = useSessions();
  const sorted = useMemo(() => sessions.data && [...sessions.data.sorted.default], [sessions.data]);
  const {
    comboBox: { filter },
  } = useSessionFilter();

  const {
    sort: { order },
  } = useSessionSort();

  const dataList = useMemo(() => sorted && getSortedSessions(filter([...sorted]), order), [filter, order, sorted]);

  const lastPage = (dataList && Math.ceil(dataList.length / pageSize)) || 1;
  const page = Math.min(currentPage, lastPage);

  const sliced = useMemo(() => dataList && dataList.slice((page - 1) * pageSize, page * pageSize), [dataList, page]);
  const list = useMemo(() => sliced && sliced.map((e) => e.id), [sliced]);

  const value = useMemo<ListContextProps>(() => ({ list, totalPages: lastPage }), [lastPage, list]);

  return useProvider(ListContext, value, children);
};
