import { FC, Fragment, useCallback, useMemo, useRef } from 'react';
import { childTestID } from '../../util/test-id';
import { CalendarSelected } from '../Calendar';
import { CalendarTabsContainer } from './containers';
import styles from './DatePicker.module.scss';
import { reducer, useReducerState } from './reducer';
import { StateResult, Tab, TabInformation, Range } from './types';
import { useBarInfo } from './useBarInfo';
import { useDispatcher } from './useDispatcher';
import { useFocusHandler } from './useFocusHandler';
import { useKeyboardHandler } from './useKeyboardHandler';
import {
  AssistiveHint,
  Bar,
  Clear,
  ContainerView,
  ContainerViewProps,
  DatePickerDialog,
  DialogPickerActions,
  InputView,
  Wrapper,
} from './views';
import { safeDate, toDateString } from '../Calendar/date-util';

export interface DatePickerProps extends ContainerViewProps {
  id: string;
  selected: Tab | null;
  tabs: TabInformation[];
  onApply: (selectedTab: Tab | null) => void;
  range: Range;
  noFutureDates?: boolean;
  maxSpanOfDaysBetweenDates?: number;
  yearFrom?: Date;
}

const useStateFromProps = (state: StateResult, { selected, tabs }: DatePickerProps) => {
  useMemo(() => {
    state.current = reducer(state.current, ['SET_TABS', tabs]);
  }, [state, tabs]);

  useMemo(() => {
    state.current = reducer(state.current, ['SET_SELECTED', selected]);
  }, [selected, state]);
};

export const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
  const { tabs, onApply, range, noFutureDates, maxSpanOfDaysBetweenDates, yearFrom, ...rest } = props;
  const state = useReducerState();
  const dispatch = useDispatcher(state);
  useStateFromProps(state, props);
  useFocusHandler(state, dispatch);

  const skipOpen = useRef(false);
  const onClick = useCallback(() => {
    if (!skipOpen.current) dispatch(['SHOW_DIALOG']);
    skipOpen.current = false;
  }, [dispatch]);

  const onClear = useCallback(() => {
    skipOpen.current = true;
    dispatch(['CLEAR_SELECTION']);
    const { selected, refs } = state.current;
    onApply(selected);
    refs.input.current?.focus();
  }, [dispatch, onApply, state]);

  const onCancel = useCallback(() => {
    skipOpen.current = true;
    dispatch(['CANCEL']);
  }, [dispatch]);

  const onKeyDown = useKeyboardHandler(state, dispatch);

  const { id, testID } = props;
  const { current } = state;
  const { open, focused, selected, activeTabPanel, refs } = current;

  const datePickerDialogId = childTestID(id, 'date-picker-dialog');
  const labelId = childTestID(id, 'label');
  const assistiveId = childTestID(id, 'assist');
  const tabsContainerId = childTestID(id, 'tabs-container');
  const actionsId = childTestID(id, 'dialog-actions');

  const canClear = !!selected && !open;

  const onSelectedChange = useCallback(
    (selected: CalendarSelected) => {
      if (noFutureDates) {
        const date = toDateString(new Date());
        const today = safeDate(date);
        if (selected.some((date) => date && +date > +today)) {
          return;
        }
      }

      if (maxSpanOfDaysBetweenDates) {
        if (selected[0] && selected[1]) {
          const diff = Math.abs(selected[1].getTime() - selected[0].getTime());
          const differenceInDays = Math.ceil(diff / (1000 * 3600 * 24));

          if (differenceInDays >= maxSpanOfDaysBetweenDates) {
            return;
          }
        }
      }
      dispatch(['SELECTION_CHANGE', selected]);
    },
    [dispatch, noFutureDates, maxSpanOfDaysBetweenDates],
  );

  const onTabSelect = useCallback(
    (id: string) => {
      dispatch(['TAB_SELECT', id]);
    },
    [dispatch],
  );

  const onApplyPress = useCallback(() => {
    skipOpen.current = true;
    const { selected } = state.current;
    dispatch(['CANCEL']);
    selected && onApply(selected);
  }, [dispatch, onApply, state]);

  const barInfo = useBarInfo({ tabs, selected, activeTabPanel, open });

  return (
    <Fragment>
      {open && <span className={styles.overlay}></span>}

      <ContainerView
        focus={focused || open}
        forwardRef={refs.container}
        onClick={onClick}
        after={
          <span>
            <Clear
              aria-label="Clear the selected date range"
              size="small"
              disabled={!canClear}
              onClick={onClear}
              testID={childTestID(testID, 'clear-button')}
            />
          </span>
        }
        {...rest}
      >
        <Wrapper>
          <Bar
            {...barInfo}
            id={labelId}
            testID={childTestID(testID, 'bar-label')}
            aria-live="polite"
            aria-expanded={open}
          />
          <InputView forwardRef={refs.input} onKeyDown={onKeyDown} id="date-picker-input" readOnly />
        </Wrapper>
        <DatePickerDialog
          id={datePickerDialogId}
          aria-labelledby={labelId}
          role="dialog"
          aria-modal="true"
          testID={childTestID(testID, 'dialog')}
          visible={open}
        >
          <CalendarTabsContainer
            id={tabsContainerId}
            selectedTab={selected}
            onSelectedChange={onSelectedChange}
            onTabSelect={onTabSelect}
            tabs={tabs}
            activeTabPanel={activeTabPanel || tabs[0].id}
            testID={childTestID(testID, 'tabs-container')}
            range={range}
            noFutureDates={noFutureDates || false}
            maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
            yearFrom={yearFrom}
          />

          <DialogPickerActions
            id={actionsId}
            testID={childTestID(testID, 'dialog-actions')}
            disabled={!selected}
            onApply={onApplyPress}
            onCancel={onCancel}
          />
          <AssistiveHint id={assistiveId}>Cursor keys can navigate dates</AssistiveHint>
        </DatePickerDialog>
      </ContainerView>
    </Fragment>
  );
};
