import clsx from 'clsx';
import { createContext, FC, KeyboardEvent, useCallback, useContext, useMemo, useRef, useState, ReactNode } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import { useDropdownMenuNavigation, useFocusLeave, usePopoverBehaviour } from './hooks';
import { reportsFilterContainer } from './DropDown.module.scss';

const PopoverContext = createContext('');

export const usePopoverClassName = (): string => useContext(PopoverContext);

export interface ContainerProps extends WithIProps<'div'> {
  open: boolean;
  downOnly?: boolean;
  onOpen: () => void;
  onClose: () => void;
  applyReportsStyles?: boolean;
  after?: ReactNode;
}

export const DropdownMenuContainer: FC<ContainerProps> = ({
  applyReportsStyles,
  after,
  open,
  downOnly,
  onOpen,
  onClose,
  testID,
  className,
  children,
  ...rest
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const inputRef = useRef(rest);
  inputRef.current = rest;

  const { containerClassName, popoverClassName } = usePopoverBehaviour(ref, open, downOnly);

  useFocusLeave(ref, open && onClose);

  const { onKeyDown: keyDown } = useDropdownMenuNavigation(
    useMemo(() => ({ container: ref, open, onOpen, onClose }), [onClose, onOpen, open, ref]),
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      keyDown(event);
      const { onKeyDown } = inputRef.current;
      if (onKeyDown) onKeyDown(event);
    },
    [keyDown],
  );

  return (
    <div
      ref={setRef}
      className={clsx(
        { [reportsFilterContainer]: applyReportsStyles, [containerClassName]: !applyReportsStyles },
        className,
      )}
      {...dataTestID(testID)}
      {...rest}
      onKeyDown={onKeyDown}
    >
      <PopoverContext.Provider value={popoverClassName}>{children}</PopoverContext.Provider>
      {after}
    </div>
  );
};
