import { ChangeEvent, FC, Ref } from 'react';
import { childTestID, dataTestID, WithIProps } from '../../../util/test-id';
import clsx from 'clsx';
import { ButtonProps, IconButton, IconProps } from '../../../components/Button';
import { Close, Search } from '../../../components/Icon';
import { TextSRegular } from '../../../theme/typography.module.scss';
import {
  empty,
  input,
  inputLabel,
  inputBox,
  screenReader,
  clear,
  hidden,
  focused,
  decor,
  icon,
} from './views.module.scss';

export interface SupervisorsComboBoxProps extends WithIProps<'div'> {
  id: string | undefined;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef: React.RefObject<HTMLInputElement>;
  input: string;
  onClear: () => void;
  focused: boolean;
  tabpanelIdList?: string;
}

export const SupervisorsComboBox: FC<SupervisorsComboBoxProps> = (props) => {
  const { onInputChange, input, onClear, forwardRef, focused, tabpanelIdList, ...rest } = props;

  const { id, testID } = props;
  const inputId = childTestID(id, 'input');
  const assistiveId = childTestID(id, 'assist');

  return (
    <InputContainerView {...rest} focus={focused} testID={childTestID(testID, 'input-container')}>
      <InputLabelView focus={focused || !!input} htmlFor={inputId} testID={childTestID(testID, 'input-label')}>
        Enter search term
      </InputLabelView>
      <InputView
        forwardRef={forwardRef}
        id={inputId}
        value={input}
        onChange={onInputChange}
        aria-describedby={assistiveId}
        aria-controls={tabpanelIdList}
        testID={childTestID(testID, 'input')}
      />
      <span>
        <Clear
          aria-label="Clear the input"
          size="small"
          disabled={!input}
          onClick={onClear}
          testID={childTestID(testID, 'clear-button')}
        />
      </span>
      <AssistiveHint id={assistiveId}>Enter search term to filter supervisors.</AssistiveHint>
    </InputContainerView>
  );
};

export type EmptyMessageProps = WithIProps<'p'>;

export const EmptyMessage: FC<EmptyMessageProps> = ({ className, testID, ...rest }) => (
  <p className={clsx(empty, TextSRegular, className)} {...dataTestID(testID)} {...rest} />
);

export interface InputViewProps extends WithIProps<'input'> {
  forwardRef?: Ref<HTMLInputElement>;
}

export const InputView: FC<InputViewProps> = ({ forwardRef, className, testID, ...rest }) => (
  <input
    ref={forwardRef}
    type="text"
    role="textbox"
    data-item="menuitem"
    aria-autocomplete="list"
    autoComplete="off"
    tabIndex={0}
    className={clsx(input, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export interface InputLabelViewProps extends WithIProps<'label'> {
  focus?: boolean;
}

export const InputLabelView: FC<InputLabelViewProps> = ({ className, focus, testID, ...rest }) => (
  <label className={clsx(inputLabel, { [focused]: focus }, className)} {...dataTestID(testID)} {...rest} />
);

export interface InputContainerViewProps extends WithIProps<'div'> {
  focus?: boolean;
}
export const InputContainerView: FC<InputContainerViewProps> = ({ className, focus, testID, children, ...rest }) => (
  <div className={clsx(inputBox, { [focused]: focus }, className)} role="search" {...dataTestID(testID)} {...rest}>
    <Decoration />
    {children}
  </div>
);

export type DecorationProps = WithIProps<'span'>;

export const Decoration: FC<DecorationProps> = ({ className, testID, children, ...rest }) => (
  <span aria-hidden="true" className={clsx(decor, className)} {...dataTestID(testID)} {...rest}>
    <Search className={icon} />
    {children}
  </span>
);

export const AssistiveHint: FC<WithIProps<'span'>> = ({ className, testID, ...rest }) => (
  <span className={clsx(screenReader, className)} {...dataTestID(testID)} {...rest} />
);

export type ClearProps = IconProps<ButtonProps>;

export const Clear: FC<ClearProps> = ({ disabled, className, children, ...rest }) => (
  <IconButton
    variant="text"
    disabled={disabled}
    className={clsx(clear, { [hidden]: disabled }, className)}
    data-item={disabled ? undefined : 'menuitem'}
    tabIndex={0}
    {...rest}
  >
    <Close />
    {children}
  </IconButton>
);
