import { useCallback } from 'react';
import { coords2date, date2coords, getDay, safeDate, toDateString } from './date-util';
import { ActiveMoveEvent, CalendarSelected } from './types';

const calculateDateDifferenceInDays = (firstDate: Date, secondDate: Date) => {
  const diff = Math.abs(firstDate.getTime() - secondDate.getTime());
  const differenceInDays = Math.ceil(diff / (1000 * 3600 * 24));

  return differenceInDays;
};

export const usePairNavigation = (
  viewMount: number,
  selected: CalendarSelected,
  noFutureDate: boolean | undefined,
  maxSpanOfDaysBetweenDates: number | undefined,
  setActive: (date: Date) => void,
): ((event: ActiveMoveEvent) => void) => {
  return useCallback(
    (event: ActiveMoveEvent) => {
      const { date, direction } = event;
      const next = safeDate(date);

      const current = toDateString(new Date());
      const safeCurrent = safeDate(current);

      if (direction === 'up') {
        if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
          const previousDate = new Date(date).setDate(date.getDate() - 7);
          const difference = calculateDateDifferenceInDays(selected[0], new Date(previousDate));

          if (difference >= maxSpanOfDaysBetweenDates) return;
        }
        next.setDate(date.getDate() - 7);
        if (next.getMonth() === date.getMonth()) {
          setActive(next);
        }
      }

      if (direction === 'down') {
        const futureDate = new Date(date).setDate(date.getDate() + 7);
        if (noFutureDate && futureDate > +safeCurrent) return;

        if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
          const difference = calculateDateDifferenceInDays(selected[0], new Date(futureDate));

          if (difference >= maxSpanOfDaysBetweenDates) return;
        }

        next.setDate(date.getDate() + 7);
        if (next.getMonth() === date.getMonth()) {
          setActive(next);
        }
      }

      if (direction === 'left') {
        if (getDay(date) > 0) {
          const previousDate = new Date(date).setDate(date.getDate() - 1);
          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], new Date(previousDate));

            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          next.setDate(next.getDate() - 1);
          if (next.getMonth() === date.getMonth()) {
            setActive(next);
          } else {
            next.setDate(next.getDate() + 7);
            setActive(next);
          }
        } else if (date.getMonth() !== viewMount || noFutureDate) {
          const previousDate = new Date(next).setMonth(next.getMonth() - 1);
          const focusedDate = coords2date(new Date(previousDate), [date2coords(date)[0], 6]);

          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], focusedDate);
            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          next.setMonth(next.getMonth() - 1);
          setActive(coords2date(next, [date2coords(date)[0], 6]));
        }
      }

      if (direction === 'right') {
        if (getDay(date) < 6) {
          const futureDate = new Date(date).setDate(date.getDate() + 1);

          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], new Date(futureDate));

            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          if (noFutureDate && futureDate > +safeCurrent) return;

          next.setDate(next.getDate() + 1);
          if (next.getMonth() === date.getMonth()) {
            setActive(next);
          } else {
            next.setDate(next.getDate() - 7);
            setActive(next);
          }
        } else if (date.getMonth() === viewMount || noFutureDate) {
          const futureDate = new Date(next).setMonth(next.getMonth() + 1);
          const focusedDate = coords2date(new Date(futureDate), [date2coords(date)[0], 0]);

          if (noFutureDate && +focusedDate > +safeCurrent) return;

          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], focusedDate);
            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          next.setMonth(next.getMonth() + 1);
          setActive(coords2date(next, [date2coords(date)[0], 0]));
        }
      }
    },
    [setActive, viewMount, maxSpanOfDaysBetweenDates, selected, noFutureDate],
  );
};
