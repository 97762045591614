import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { SubheadingBold, TextSBold } from '../../theme/typography.module.scss';
import { dataTestID, WithIProps } from '../../util/test-id';
import { ButtonProps, IconButton } from '../Button';
import { DropdownMenuList, MenuListProps } from '../DropDown';
import { Close, NotificationsBell } from '../Icon';
import {
  activeMessagesButton,
  bellIcon,
  border,
  closeButton,
  emptyState,
  messageButton,
  messagesDropDown,
  messagesListTitle,
  messagesWrapper,
  show,
  stateMessage,
} from './styles.module.scss';

export const MessagesDropDownList: FC<MenuListProps> = ({ className, ...rest }) => (
  <DropdownMenuList className={clsx(messagesDropDown, className)} {...rest} />
);

interface MessagesButtonProps extends ButtonProps {
  count: number;
  showCount: boolean;
  active: boolean;
}

export const MessagesButton: FC<MessagesButtonProps> = ({
  count,
  showCount,
  className,
  testID,
  children,
  id,
  active,
  ...rest
}) => {
  return (
    <IconButton
      id={id}
      variant="text"
      active={active}
      className={clsx(messageButton, { [show]: showCount, [activeMessagesButton]: active }, className)}
      data-count={count}
      {...dataTestID(testID, id)}
      {...rest}
    >
      <NotificationsBell />
      {children}
    </IconButton>
  );
};

interface MessagesListTitleProps extends Omit<WithIProps<'span'>, 'role'> {
  onClose?: () => void;
  role?: string;
}

export const MessagesListTitle: FC<MessagesListTitleProps> = ({ id, role, onClose, testID, className, ...rest }) => (
  <span role={role} className={clsx(TextSBold, messagesListTitle, className)} {...dataTestID(testID, id)} {...rest}>
    <NotificationsBell className={bellIcon} />
    <span>Notifications</span>
    <IconButton
      size="small"
      aria-label="close"
      className={closeButton}
      variant="text"
      onClick={onClose}
      data-item="menuitem"
      tabIndex={-1}
      {...dataTestID(testID, 'close')}
    >
      <Close />
    </IconButton>
  </span>
);

interface SeparatorProps extends Omit<WithIProps<'hr'>, 'role'> {
  role?: string;
}

export const Separator: FC<SeparatorProps> = ({ className, testID, role, ...rest }) => (
  <hr role={role} className={clsx(border, className)} {...dataTestID(testID, 'separator')} {...rest} />
);

interface MessagesWrapperProps extends Omit<WithIProps<'div'>, 'role'> {
  list: ReactNode[];
  loading: boolean;
  role?: string;
}

export const MessagesWrapper: FC<MessagesWrapperProps> = ({ list, className, loading, testID, role, ...rest }) => (
  <div
    role={role}
    className={clsx(messagesWrapper, { [emptyState]: loading || !list.length }, className)}
    {...dataTestID(testID, 'messages-list-wrapper')}
    {...rest}
  >
    {loading && <LoadingListMessage testID={testID} />}
    {!loading && !list.length && <EmptyListMessage testID={testID} />}
    {!loading && !!list.length && list}
  </div>
);

export const LoadingListMessage: FC<WithIProps<'span'>> = ({ className, testID, ...rest }) => (
  <span
    className={clsx(stateMessage, SubheadingBold, className)}
    {...dataTestID(testID, 'loading-list-message')}
    {...rest}
  >
    Loading messages
  </span>
);

export const EmptyListMessage: FC<WithIProps<'span'>> = ({ className, testID, ...rest }) => (
  <span
    className={clsx(stateMessage, SubheadingBold, className)}
    {...dataTestID(testID, 'empty-list-message')}
    {...rest}
  >
    No messages to review
  </span>
);
